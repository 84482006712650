/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Button,
} from 'reactstrap';
import { useQuery } from 'react-query';
import moment from 'moment';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import {
  getApexAccountDetails, getAllOrdersTransfersByAcct, getPdtHistory, getUserInfoByApexAccountNumber, getIdentityDocsByAccountNumber, getInternalInvestigationHistory,
} from 'Services/AccountServices';
import IdentityTable from 'Screens/Components/IdentityTable/IdentityTable';
import routes from '../../routes';
import SimpleTable from '../Components/SortingTable/SortingTable';
import ReactTable from '../Components/ReactTable/ReactTable';
import Sidebar from '../Components/Sidebar/Sidebar';

export default function AccountDetails() {
  const { accountNumber } = useParams();
  const [accountOverviewTable, setAccountOverviewTable] = React.useState([]);
  const [accountEntitlementsTable, setAccountEntitlementsTable] = React.useState([]);
  const [customerProfileTable, setCustomerProfileTable] = React.useState([]);
  const [achRelationshipTable, setAchRelationshipTable] = React.useState([]);
  const [transfersTable, setTransfersTable] = React.useState([]);
  const [ordersTable, setOrdersTable] = React.useState([]);
  const [pdtTable, setPdtTable] = React.useState([]);
  const [suitabilityInfoTable, setSuitabilityInfoTable] = React.useState([]);
  const [financialProfileTable, setFinancialProfileTable] = React.useState([]);
  const [accountDocumentsTable, setAccountDocumentsTable] = React.useState([]);
  const [trustedContactTable, setTrustedContactTable] = React.useState([]);
  const [showAlert, setShowAlert] = React.useState(false);
  const [deletingAccount, setDeletingAccount] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState(null);
  const [allIdentityDocumentsTable, setAllIdentityDocumentsTable] = React.useState(null);
  const [internalInvestigationTable, setInternalInvestigationTable] = React.useState(null);

  function handleDeleteClick() {
    setShowAlert(true);
  }

  function handleAlertConfirm() {
    // Handle delete logic here
    setDeletingAccount(true);
    setShowAlert(false);
  }

  function handleAlertCancel() {
    setShowAlert(false);
  }

  const navigate = useNavigate();

  function handlePressOrders(externalId) {
    navigate(`/admin/orders/${externalId}`);
  }

  function handlePressTransfers(externalTransferId) {
    navigate(`/admin/transfers/${externalTransferId}`);
  }

  function handlePressAch(achRelationshipId) {
    navigate(`/admin/achrelationships/${achRelationshipId}`, { state: { achRelationshipData } });
  }

  const getAllOrdersTransfersByAcctQuery = useQuery('getAllOrdersTransfersByAcct', () => getAllOrdersTransfersByAcct(accountNumber), {
    onSuccess: (data) => {
      const transfersTableVar = data?.Transfers.map((prop, key) => ({
        id: key,
        amount: prop.amount,
        account: prop.account,
        status: prop.state,
        reason: prop.reason,
        transferId: prop.transferId,
        externalTransferId: prop.externalTransferId,
        type: prop.type,
        uuid: prop.id,
        dateSubmitted: moment(prop.originaldatetime).format('MM/DD/YYYY HH:mm:ss A'),
        lastUpdated: moment(prop.timestamp).format('MM/DD/YYYY HH:mm:ss A'),
        actions: (
        // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                handlePressTransfers(prop.externalTransferId);
              }}
              color="#000000"
              size="sm"
            >
              <i className="tim-icons icon-double-right" />
            </Button>
            {' '}

          </div>
        ),

      }));
      setTransfersTable(transfersTableVar);

      const ordersTableVar = data?.Orders.map((prop, key) => ({
        id: key,
        status: prop.status,
        account: prop.account,
        externalId: prop.externalId,
        symbol: prop.symbol,
        side: prop.side,
        shareQuantity: prop.shareQuantity,
        avgPrice: prop.avgPrice,
        notional: prop.notional,
        uuid: prop.id,
        dateSubmitted: moment(prop.originaldateTime).format('MM/DD/YYYY HH:mm:ss A'),
        lastUpdated: moment(prop.datetime).format('MM/DD/YYYY HH:mm:ss A'),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                handlePressOrders(prop.externalId);
              }}
              color="#000000"
              size="sm"

            >
              <i className="tim-icons icon-double-right" />
            </Button>
            {' '}

          </div>
        ),

      }));
      setOrdersTable(ordersTableVar);
    },

  });

  const getPdtHistoryQuery = useQuery('getPdtHistory', () => getPdtHistory(accountNumber), {
    onSuccess: (data) => {
      const pdtTableVar = data?.map((prop, key) => ({
        id: key,
        status: prop.status,
        account: prop.account,
        externalid: prop.externalid,
        symbol: prop.symbol,
        side: prop.side,
        sharequantity: prop.sharequantity,
        avgprice: prop.avgprice,
        notional: prop.notional,
        uuid: prop.id,
        day: moment(prop.datetime).format('MM/DD/YYYY HH:mm:ss A'),
        actions: (
        // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                handlePressOrders(prop.externalid);
              }}
              color="#000000"
              size="sm"
            >
              <i className="tim-icons icon-double-right" />
            </Button>
            {' '}

          </div>
        ),

      }));
      setPdtTable(pdtTableVar);
    },

  });

  const getApexAccountDetailsQuery = useQuery('getApexAccountDetails', () => getApexAccountDetails(accountNumber), {
    onSuccess: (data) => {
      if (data?.achRelationships) {
        setAchRelationshipData(data?.achRelationships);
      }

      const acctOverview = [
        { label: 'Account Type', value: data?.accountOverview.accountType },
        { label: 'Account Sub Type', value: data?.accountOverview.accountSubType },
        { label: 'Margin Type', value: data?.accountOverview.marginType },
        { label: 'Account Number', value: data?.accountOverview.accountNumber },
        { label: 'Maintenance Requirement', value: data?.accountOverview.maintenanceRequirement },
        { label: 'Opened On', value: data?.accountOverview.openedOn },
        { label: 'Status', value: data?.accountOverview.accountStatus },
      ];
      setAccountOverviewTable(acctOverview);

      const accountEntitlements = [
        { label: 'FDIC Sweep', value: data?.accountEntitlements.fdicSweep ? 'True' : 'False' },
        { label: 'Instant Deposits', value: data?.accountEntitlements.instantDeposits },
        { label: 'Instant Deposits Limit', value: data?.accountEntitlements.instantDepositLimit },
        { label: 'Equities', value: data?.accountEntitlements.equities },
        { label: 'Options', value: data?.accountEntitlements.options },
        { label: 'Instant Buying Power', value: data?.accountEntitlements.instantBp },
        { label: 'Dividend Reinvestment', value: data?.accountEntitlements.dividendReinvestment },
      ];
      setAccountEntitlementsTable(accountEntitlements);

      const customerProfile = [
        { label: 'Name', value: data?.customerProfile.name },
        { label: 'Address', value: data?.customerProfile.address },
        { label: 'DOB', value: data?.customerProfile.dob },
        { label: 'SSN', value: data?.customerProfile.ssn },
        { label: 'Phone Number', value: data?.customerProfile.phone },
        { label: 'Email', value: data?.customerProfile.email },
        { label: 'Citizenship', value: data?.customerProfile.citizenship },
        { label: 'Employment', value: data?.customerProfile.employment },
        { label: 'Employer Name', value: data?.customerProfile.employerName },
        { label: 'Job Title', value: data?.customerProfile.jobTitle },
        { label: 'Politically Exposed', value: data?.customerProfile.politicallyExposed },
        { label: 'Public Company', value: data?.customerProfile.publicCompany },
        { label: 'Broker Dealer', value: data?.customerProfile.brokerDealer },
      ];
      setCustomerProfileTable(customerProfile);

      const achRelationships = data?.achRelationships ? data?.achRelationships.map((prop, key) => ({
        nickname: prop.nickname,
        relationshipId: prop.id,
        status: prop.status,
        createdOn: moment(prop.creationTime).format('MM/DD/YYYY HH:mm:ss A'),
        lastUpdated: moment(prop.status === 'APPROVED' ? prop.approval.approvalTime : prop.status === 'REJECTED' ? prop.rejection.rejectionTime : prop.cancellation.cancellationTime).format('MM/DD/YYYY HH:mm:ss A'),
        actions: (
        // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                handlePressAch(prop.id);
              }}
              color="#000000"
              size="sm"
              disabled={data?.achRelationships.length === 0}
            >
              <i className="tim-icons icon-double-right" />
            </Button>
            {' '}

          </div>
        ),
      })) : null;

      setAchRelationshipTable(achRelationships);

      const suitabilityInfo = [
        { label: 'Risk Tolerance', value: data?.suitabilityInfo?.riskTolerance },
        { label: 'Experience', value: data?.suitabilityInfo?.experience },
        { label: 'Investment Objective', value: data?.suitabilityInfo?.objective },
        { label: 'Time Horizon', value: data?.suitabilityInfo?.timeHorizon },
        { label: 'Liquidity Needs', value: data?.suitabilityInfo?.liquidityNeeds },
      ];
      setSuitabilityInfoTable(suitabilityInfo);

      const financialProfile = [
        { label: 'Net Worth', value: data?.financialProfile?.netWorth },
        { label: 'Liquid Net Worth', value: data?.financialProfile?.liquidNetWorth },
        { label: 'Annual Income', value: data?.financialProfile?.annualIncome },
      ];
      setFinancialProfileTable(financialProfile);

      const accountDocuments = data?.accountDocuments ? data?.accountDocuments.map((doc) => ([
        { label: 'Code', value: doc.code },
        { label: 'Name', value: doc.name },
        { label: 'Status', value: doc.status },
        { label: 'Required', value: doc.required ? 'True' : 'False' },
        { label: 'Status Updated Date', value: doc.statusUpdatedDate },
      ])) : null;
      setAccountDocumentsTable(accountDocuments);

      if (data?.trustedContact) {
        const trustedContact = [
          { label: 'Name', value: data?.trustedContact.name },
          { label: 'Phone Number', value: data?.trustedContact.phone },
          { label: 'Email', value: data?.trustedContact.email },
        ];
        setTrustedContactTable(trustedContact);
      }
    },
  });

  const getInternalInvestigationQuery = useQuery(
    'getInternalInvestigation',
    () => getInternalInvestigationHistory(accountNumber),
    {
      onSuccess: (data) => {
        setInternalInvestigationTable(data);
      },
    },
  );

  const handleRefreshInternalHistory = () => {
    getInternalInvestigationQuery.refetch();
  };

  const getUserQuery = useQuery(
    'getUser',
    () => getUserInfoByApexAccountNumber(accountNumber),
    {
      onSuccess: (data) => {
        setUserInfo(data);
      },
    },
  );

  const getIdentityDocsQuery = useQuery(
    'getIdentityDocs',
    () => getIdentityDocsByAccountNumber(accountNumber),
    {
      onSuccess: (data) => {
        setAllIdentityDocumentsTable(data);
      },
    },
  );

  const [achRelationshipData, setAchRelationshipData] = React.useState(getApexAccountDetailsQuery?.data?.achRelationships || []);

  // The point of this is that since we first set the button's disabled in the original data fetch, it needs to be updated when the data state is set in the original data fetch
  useEffect(() => {
    if (getApexAccountDetailsQuery?.data?.achRelationships.length > 0) {
      const achRelationships = getApexAccountDetailsQuery?.data?.achRelationships.map((prop, key) => ({
        nickname: prop.nickname,
        relationshipId: prop.id,
        status: prop.status,
        createdOn: moment(prop.creationTime).format('MM/DD/YYYY HH:mm:ss A'),
        lastUpdated: moment(prop.status === 'APPROVED' ? prop.approval.approvalTime : prop.status === 'REJECTED' ? prop.rejection.rejectionTime : prop.cancellation.cancellationTime).format('MM/DD/YYYY HH:mm:ss A'),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                handlePressAch(prop.id);
              }}
              color="#000000"
              size="sm"
              disabled={achRelationshipData.length === 0}
            >
              <i className="tim-icons icon-double-right" />
            </Button>
            {' '}

          </div>
        ),
      }));
      setAchRelationshipTable(achRelationships);
    }
  }, [achRelationshipData]);

  return (
    <div className="flex h-screen bg-zinc-800">
      <Sidebar routes={routes} activeColor="blue" />
      <div className="flex justify-center items-start mt-10">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">Account Details</h2>
          <Row style={{ justifyContent: 'center' }}>
            <Button
              className="btn-round btn-just-icon"
              color="danger"
              disabled={deletingAccount}
              onClick={handleDeleteClick}
            >
              {deletingAccount ? 'Closing Account...' : 'Close Account'}
            </Button>
            <ReactBSAlert
              show={showAlert}
              title="Close Account"
              onConfirm={handleAlertConfirm}
              onCancel={handleAlertCancel}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              confirmBtnText="Yes, close it!"
              cancelBtnText="Cancel"
              showCancel
            >
              Are you sure you want to close this account?
            </ReactBSAlert>
          </Row>
        </Col>

        <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Hedge User Info for Account:
                    {' '}
                    {accountNumber}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {userInfo ? (
                    <SimpleTable
                      thead={[
                        { text: 'First Name' },
                        { text: 'Last Name' },
                        { text: 'Email' },
                        { text: 'Cognito User' },
                      ]}
                      tbody={[
                        {
                          data: [
                            { text: userInfo?.User?.first_name },
                            { text: userInfo?.User?.last_name },
                            { text: userInfo?.User?.email },
                            { text: userInfo?.User?.user_name },
                          ],
                        },
                      ]}
                    />
                  ) : (
                    <text> loading... </text>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Account Overview For:
                    {' '}
                    {accountNumber}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {accountOverviewTable && accountOverviewTable.length > 1 ? (
                    <SimpleTable
                      thead={[{ text: 'Label' }, { text: 'Value' }]}
                      tbody={accountOverviewTable.map((row) => ({
                        data: [{ text: row.label }, { text: row.value }],
                      }))}
                    />
                  ) : (
                    <text> loading... </text>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Account Entitlements and Permissions For:
                    {' '}
                    {accountNumber}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {accountEntitlementsTable
                  && accountEntitlementsTable.length > 1 ? (
                    <SimpleTable
                      thead={[{ text: 'Label' }, { text: 'Value' }]}
                      tbody={accountEntitlementsTable.map((row) => ({
                        data: [{ text: row.label }, { text: row.value }],
                      }))}
                    />
                    ) : (
                      <text> loading... </text>
                    )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Customer Profile For:
                    {' '}
                    {accountNumber}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {customerProfileTable && customerProfileTable.length > 1 ? (
                    <SimpleTable
                      thead={[{ text: 'Label' }, { text: 'Value' }]}
                      tbody={customerProfileTable.map((row) => ({
                        data: [{ text: row.label }, { text: row.value }],
                      }))}
                    />
                  ) : (
                    <text> loading... </text>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        {achRelationshipTable && achRelationshipTable.length > 0 && (
          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      ACH Relationships For:
                      {' '}
                      {accountNumber}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {achRelationshipTable && achRelationshipTable.length > 0 ? (
                      <ReactTable
                        data={achRelationshipTable}
                        filterable
                        resizable={false}
                        columns={[
                          {
                            Header: 'Nickname',
                            accessor: 'nickname',
                          },
                          {
                            Header: 'Relationship Id',
                            accessor: 'relationshipId',
                          },
                          {
                            Header: 'Status',
                            accessor: 'status',
                          },
                          {
                            Header: 'Created On',
                            accessor: 'createdOn',
                          },
                          {
                            Header: 'Last Updated',
                            accessor: 'lastUpdated',
                          },
                          {
                            Header: 'Details',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className="-striped -highlight"
                      />
                    ) : (
                      <text> loading </text>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}

        {suitabilityInfoTable && suitabilityInfoTable.length > 1 && (
          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Suitability Info For
                      {' '}
                      {accountNumber}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {suitabilityInfoTable && suitabilityInfoTable.length > 1 ? (
                      <SimpleTable
                        thead={[{ text: 'Label' }, { text: 'Value' }]}
                        tbody={suitabilityInfoTable.map((row) => ({
                          data: [{ text: row.label }, { text: row.value }],
                        }))}
                      />
                    ) : (
                      <text> loading... </text>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}

        <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Financial Profile For:
                    {' '}
                    {accountNumber}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {financialProfileTable && financialProfileTable.length > 1 ? (
                    <SimpleTable
                      thead={[{ text: 'Label' }, { text: 'Value' }]}
                      tbody={financialProfileTable.map((row) => ({
                        data: [{ text: row.label }, { text: row.value }],
                      }))}
                    />
                  ) : (
                    <text> loading... </text>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {trustedContactTable && trustedContactTable.length > 1 && (
          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Trusted Contact For:
                      {' '}
                      {accountNumber}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {trustedContactTable && trustedContactTable.length > 1 ? (
                      <SimpleTable
                        thead={[{ text: 'Label' }, { text: 'Value' }]}
                        tbody={trustedContactTable.map((row) => ({
                          data: [{ text: row.label }, { text: row.value }],
                        }))}
                      />
                    ) : (
                      <text> loading... </text>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}

        {accountDocumentsTable && accountDocumentsTable.length > 1 && (
          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Account Documents For:
                      {' '}
                      {accountNumber}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {accountDocumentsTable
                    && accountDocumentsTable.length > 1 ? (
                      <SimpleTable
                        thead={[
                          { text: 'Code' },
                          { text: 'Name' },
                          { text: 'Status' },
                          { text: 'Required' },
                          { text: 'Status Updated Date' },
                        ]}
                        tbody={accountDocumentsTable.map((rows) => ({
                          data: rows.map((row) => ({ text: row.value })),
                        }))}
                      />
                      ) : (
                        <text> loading... </text>
                      )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}

        {transfersTable && transfersTable.length > 0 && (
          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Transfers For:
                      {' '}
                      {accountNumber}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {transfersTable && transfersTable.length > 0 ? (
                      <ReactTable
                        data={transfersTable}
                        filterable
                        resizable={false}
                        columns={[
                          {
                            Header: 'Amount',
                            accessor: 'amount',
                          },
                          {
                            Header: 'Account',
                            accessor: 'account',
                          },
                          {
                            Header: 'Overall Status',
                            accessor: 'status',
                          },
                          {
                            Header: 'Reason',
                            accessor: 'reason',
                          },
                          {
                            Header: 'Transfer ID',
                            accessor: 'transferId',
                          },
                          {
                            Header: 'External Transfer ID',
                            accessor: 'externalTransferId',
                          },
                          {
                            Header: 'Type',
                            accessor: 'type',
                          },
                          {
                            Header: 'UUID',
                            accessor: 'uuid',
                          },
                          {
                            Header: 'Date Submitted',
                            accessor: 'dateSubmitted',
                          },
                          {
                            Header: 'Last Updated',
                            accessor: 'lastUpdated',
                          },
                          {
                            Header: 'Details',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className="-striped -highlight"
                      />
                    ) : (
                      <text> loading </text>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}

        {ordersTable && ordersTable.length > 0 && (
          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Orders For:
                      {accountNumber}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {ordersTable && ordersTable.length > 0 ? (
                      <ReactTable
                        data={ordersTable}
                        filterable
                        resizable={false}
                        columns={[
                          {
                            Header: 'Overall Status',
                            accessor: 'status',
                          },
                          {
                            Header: 'Account',
                            accessor: 'account',
                          },
                          {
                            Header: 'Symbol',
                            accessor: 'symbol',
                          },
                          {
                            Header: 'Side',
                            accessor: 'side',
                          },
                          {
                            Header: 'Share Quantity',
                            accessor: 'shareQuantity',
                          },
                          {
                            Header: 'Avg Price',
                            accessor: 'avgPrice',
                          },
                          {
                            Header: 'Notional',
                            accessor: 'notional',
                          },
                          {
                            Header: 'External ID',
                            accessor: 'externalId',
                          },

                          {
                            Header: 'UUID',
                            accessor: 'uuid',
                          },
                          {
                            Header: 'Date Submitted',
                            accessor: 'dateSubmitted',
                          },
                          {
                            Header: 'Last Updated',
                            accessor: 'lastUpdated',
                          },
                          {
                            Header: 'Details',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className="-striped -highlight"
                      />
                    ) : (
                      <text> loading </text>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}

        {pdtTable && pdtTable.length > 0 && (
          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Day Trades For
                      {' '}
                      {accountNumber}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {pdtTable && pdtTable.length > 0 ? (
                      <ReactTable
                        data={pdtTable}
                        filterable
                        resizable={false}
                        columns={[
                          {
                            Header: 'Overall Status',
                            accessor: 'status',
                          },
                          {
                            Header: 'Account',
                            accessor: 'account',
                          },
                          {
                            Header: 'Symbol',
                            accessor: 'symbol',
                          },
                          {
                            Header: 'Side',
                            accessor: 'side',
                          },
                          {
                            Header: 'Share Quantity',
                            accessor: 'sharequantity',
                          },
                          {
                            Header: 'Avg Price',
                            accessor: 'avgprice',
                          },
                          {
                            Header: 'Notional',
                            accessor: 'notional',
                          },
                          {
                            Header: 'External ID',
                            accessor: 'externalid',
                          },

                          {
                            Header: 'UUID',
                            accessor: 'uuid',
                          },
                          {
                            Header: 'Day',
                            accessor: 'day',
                          },
                          {
                            Header: 'Details',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className="-striped -highlight"
                      />
                    ) : (
                      <text> loading </text>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
        <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Hedge Internal Investigation History for Account:
                    {' '}
                    {accountNumber}
                  </CardTitle>
                  <Button
                    onClick={() => {
                      handleRefreshInternalHistory();
                    }}
                    color="#000000"
                    size="sm"
                  >
                    <i className="tim-icons icon-refresh-01" />
                  </Button>
                </CardHeader>
                <CardBody>
                  {!getInternalInvestigationQuery.isLoading
                  && internalInvestigationTable
                  && internalInvestigationTable.length > 0 ? (
                    <ReactTable
                      tableRoute="internalInvestigationHistory"
                      data={internalInvestigationTable}
                      filterable
                      resizable={false}
                      columns={[
                        {
                          Header: 'Id',
                          accessor: 'id',
                        },
                        {
                          Header: 'Type',
                          accessor: 'type',
                        },
                        {
                          Header: 'Account Number',
                          accessor: 'apexAccountNumber',
                        },
                        {
                          Header: 'Status',
                          accessor: 'status',
                        },
                        {
                          Header: 'Sketch Request Id',
                          accessor: 'sketchRequestId',
                        },
                        {
                          Header: 'Created At',
                          accessor: 'dateTime',
                        },
                      ]}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                    ) : getInternalInvestigationHistory.isLoading ? (
                      <text> loading... </text>
                    ) : (
                      <text> No internal investigation history found </text>
                    )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        {allIdentityDocumentsTable && allIdentityDocumentsTable.length > 0 && (
          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      All Identity Documents for Cognito Account:
                      {' '}
                      {userInfo?.User?.user_name}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {!getIdentityDocsQuery.isLoading
                    && allIdentityDocumentsTable.length > 0 ? (
                      <IdentityTable
                        thead={[{ text: 'Document Type' }, { text: 'File' }]}
                        tbody={allIdentityDocumentsTable.map((document) => ({
                          data: [
                            { text: document.documentType },
                            {
                              text: document.fileName,
                              url: document.url, // Add the URL here
                            },
                          ],
                        }))}
                      />
                      ) : getIdentityDocsQuery.isLoading ? (
                        <text> loading... </text>
                      ) : (
                        <text> No documents found </text>
                      )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
}
