import React from 'react';
// eslint-disable-next-line import/no-unresolved
import routes from 'routes.js';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  CardTitle,
} from 'reactstrap';
import { useQuery } from 'react-query';
// eslint-disable-next-line import/no-unresolved
import { getOrderHistoryById } from 'Services/AccountServices';
import moment from 'moment';
import SimpleTable from '../Components/SortingTable/SortingTable';
import Sidebar from '../Components/Sidebar/Sidebar';

export default function OrderDetails() {
  const { externalId } = useParams();
  const [ordersByAccountTable, setOrdersByAccountTable] = React.useState(null);

  // eslint-disable-next-line no-unused-vars
  const getAllOrdersQuery = useQuery('getOrdersById', () => getOrderHistoryById(externalId), {
    onSuccess: (data) => {
      const ordersTables = data.map((reason) => ([
        { label: 'Status', value: reason.status },
        { label: 'Account', value: reason.account },
        { label: 'Symbol', value: reason.symbol },
        { label: 'Side', value: reason.side },
        { label: 'Share Quantity', value: reason.shareQuantity },
        { label: 'Avg Price', value: reason.avgPrice },
        { label: 'Notional', value: reason.notional },
        { label: 'External ID', value: reason.externalId },
        { label: 'UUID', value: reason.id },
        { label: 'Date Submitted', value: moment(reason.dateTime).format('MM/DD/YYYY HH:mm:ss A') },
      ]));
      setOrdersByAccountTable(ordersTables);
    },
  });

  return (
    <div className="flex h-screen bg-zinc-800">
      <Sidebar
        routes={routes}
        activeColor="blue"
      />
      <div className="flex justify-center items-start mt-10">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">Order Details</h2>
        </Col>
        <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Order History for
                    {' '}
                    {externalId}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {ordersByAccountTable && ordersByAccountTable.length > 0 ? (
                    <SimpleTable
                      thead={[{ text: 'Status' }, { text: 'Account' }, { text: 'Symbol' }, { text: 'Side' }, { text: 'Share Quantity' }, { text: 'Avg Price' }, { text: 'Notional' }, { text: 'External ID' }, { text: 'UUID' }, { text: 'Date Submitted' }]}
                      tbody={ordersByAccountTable.map((rows) => ({
                        data: rows.map((row) => ({ text: row.value })),
                      }))}
                    />

                  ) : (
                    <text> loading... </text>
                  )}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
