/* eslint-disable quotes */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable react/button-has-type */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import "ag-grid-enterprise";
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { useQuery } from 'react-query';
import { getCombinedDiff } from 'Services/WhitelistService';
import { useDispatch } from 'react-redux';
import {
  addStockToAdditions, removeStockFromAdditions, addStockToRemovals, removeStockFromRemovals,
} from 'store/Reducers/whitelist';
import { Button } from 'reactstrap';
import TableStats from './TableStats';

export default function CombinedDiffTable() {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState([]);
  const [dataType, setDataType] = useState('ADD');
  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {
      field: 'ticker', filter: true, headerCheckboxSelection: true, checkboxSelection: true,
    },
    { field: 'name', filter: true },
    { field: 'active' },
    { field: 'type' },
    { field: 'locale' },
    { field: 'currency_name' },
    { field: 'cik' },
    { field: 'composite_figi' },
    { field: 'share_class_figi' },
    { field: 'market' },
    { field: 'primary_exchange' },
    { field: 'delisted_utc' },
    { field: 'sic_code' },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));

  const combinedDiffQuery = useQuery('combinedDiff', () => getCombinedDiff(), {
    onSuccess: (data) => {
      if (dataType === 'ADD') {
        setChartData(data.symbolsToAdd);
        setColumnDefs((prev) => {
          prev[0].field = 'ticker';
          prev[1].field = 'name';
          return prev;
        });
      } else {
        setChartData(data.symbolsToRemove);
        setColumnDefs((prev) => {
          prev[0].field = 'symbol';
          prev[1].field = 'securityName';
          return prev;
        });
      }
    },
  });

  const icons = useMemo(() => ({
    'custom-stats': '<span class="ag-icon ag-icon-custom-stats"></span>',
  }), []);

  const sideBar = useMemo(() => ({
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
      {
        id: 'customStats',
        labelDefault: 'Stats',
        labelKey: 'customStats',
        iconKey: 'custom-stats',
        toolPanel: TableStats,
      },
    ],
    defaultToolPanel: 'customStats',
  }), []);

  const handleChangePress = (e) => {
    setDataType(dataType === 'ADD' ? 'REMOVE' : 'ADD');
    combinedDiffQuery.refetch();
  };

  const handleRowSelected = (e) => {
    const { data, node } = e;
    if (dataType === 'ADD') {
      if (node.selected)dispatch(addStockToAdditions(data));
      else dispatch(removeStockFromAdditions(data));
    } else if (dataType === 'REMOVE') {
      if (node.selected)dispatch(addStockToRemovals(data));
      else dispatch(removeStockFromRemovals(data));
    }
  };

  const handleRefresh = async () => {
    await combinedDiffQuery.refetch();
  };
  return (
    <div style={{ height: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <h1>Combined Diff (Apex/Polygon)</h1>
        <Button style={{ marginBottom: '2%', marginLeft: '3%' }} onClick={handleRefresh} size="md">
          Refresh
        </Button>
      </div>
      <h2>
        Symbols to
        <span style={{ fontWeight: 'bold' }}>
          {' '}
          {dataType}
        </span>
      </h2>
      <button
        onClick={handleChangePress}
        style={{ borderRadius: 20, padding: 10, marginBottom: 5 }}
      >
        Switch to symbols to
        {' '}
        {dataType === 'ADD' ? 'REMOVE' : 'ADD'}
      </button>
      <AgGridReact
        className="ag-theme-alpine"
        ref={gridRef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows
        rowSelection="multiple"
        rowData={chartData}
        rowMultiSelectWithClick
        sideBar={sideBar}
        icons={icons}
        onRowSelected={handleRowSelected}
      />
    </div>
  );
}
