/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { useQuery } from 'react-query';
import {
  Card, CardBody, CardTitle, Row, Col, Spinner,
} from 'reactstrap';
import GroupInvestingService from 'Services/GroupInvestingService';

export default function SubEventList({ masterId }) {
  const {
    data, isError, isLoading, isFetching,
  } = useQuery(
    ['withdrawalSubEvents', masterId],
    () => GroupInvestingService.getWithdrawalSubEvents(masterId),
  );

  if (isLoading) {
    return <Spinner color="primary" />;
  }

  if (isError) {
    return <div>Error loading sub events</div>;
  }

  const sortedData = data
    ? [...data].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    : [];

  return (
    <div>
      {isFetching && <Spinner color="primary" />}
      {sortedData && sortedData.length > 0 ? (
        sortedData.map((subEvent) => (
          <Card key={subEvent.id} className="bg-primary text-white mb-2">
            <CardBody>
              <CardTitle tag="h5">
                {subEvent.type}
                {' '}
                -
                {subEvent.state}
              </CardTitle>
              <Row>
                <Col md="6">
                  <p className="h4">
                    <strong>Amount:</strong>
                    {' '}
                    {subEvent.amount || 'N/A'}
                  </p>
                  <p className="h4">
                    <strong>Shares:</strong>
                    {' '}
                    {subEvent.sharequantity || 'N/A'}
                  </p>
                  <p className="h4">
                    <strong>Symbol:</strong>
                    {' '}
                    {subEvent.symbol || 'N/A'}
                  </p>
                  <p className="h4">
                    <strong>Source Account:</strong>
                    {' '}
                    {subEvent.sourceaccount}
                  </p>
                  <p className="h4">
                    <strong>Destination Account:</strong>
                    {' '}
                    {subEvent.destinationaccount}
                  </p>
                </Col>
                <Col md="6">
                  <p className="h4">
                    <strong>State:</strong>
                    {' '}
                    {subEvent.state}
                  </p>
                  <p className="h4">
                    <strong>Datetime:</strong>
                    {' '}
                    {new Date(subEvent.timestamp).toLocaleString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      fractionalSecondDigits: 3,
                    })}
                    {' '}
                  </p>
                  <p className="h4">
                    <strong>Transfer ID:</strong>
                    {' '}
                    {subEvent.transferid}
                  </p>
                  <p className="h4">
                    <strong>External Transfer ID:</strong>
                    {' '}
                    {subEvent.externaltransferid}
                  </p>
                  <p className="h4">
                    <strong>Withdrawal Item ID:</strong>
                    {' '}
                    {subEvent.withdrawalitemid}
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        ))
      ) : (
        <div>No sub events found</div>
      )}
    </div>
  );
}
