/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  queuedAdditionsObj: {},
  queuedRemovalsObj: {},
  queuedEditObj: {},
};

export const whitelistSlice = createSlice({
  name: 'whitelist',
  initialState,
  reducers: {
    addStockToAdditions: (state, action) => {
      if (action.payload?.ticker) {
        state.queuedAdditionsObj[action.payload.ticker] = action.payload;
      } else if (action.payload?.symbol) {
        state.queuedAdditionsObj[action.payload.symbol] = action.payload;
      }
    },
    removeStockFromAdditions: (state, action) => {
      if (action.payload?.symbol) delete state.queuedAdditionsObj[action.payload.symbol];
      else if (action.payload?.ticker) delete state.queuedAdditionsObj[action.payload.ticker];
    },
    addStockToRemovals: (state, action) => {
      if (action.payload?.ticker) {
        state.queuedRemovalsObj[action.payload.ticker] = action.payload;
      } else if (action.payload?.symbol) {
        state.queuedRemovalsObj[action.payload.symbol] = action.payload;
      }
    },
    removeStockFromRemovals: (state, action) => {
      if (action.payload?.symbol) delete state.queuedRemovalsObj[action.payload.symbol];
      else if (action.payload?.ticker) delete state.queuedRemovalsObj[action.payload.ticker];
    },
    addStockToEdits: (state, action) => {
      const key = action.payload?.ticker || action.payload?.symbol;
      if (key) {
        if (state.queuedEditObj[key]) {
          // If the item already exists, update it
          delete state.queuedEditObj[key];
          state.queuedEditObj[key] = action.payload;
        } else {
          // If the item does not exist, add it
          state.queuedEditObj[key] = action.payload;
        }
      }
    },

    removeStockFromEdits: (state, action) => {
      if (action.payload?.symbol) delete state.queuedEditObj[action.payload.symbol];
      else if (action.payload?.ticker) delete state.queuedEditObj[action.payload.ticker];
    },
    clearAllWhitelistState: (state) => {
      state.queuedAdditionsObj = {};
      state.queuedRemovalsObj = {};
      state.queuedEditObj = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addStockToAdditions, removeStockFromAdditions, addStockToRemovals, removeStockFromRemovals, addStockToEdits, removeStockFromEdits, clearAllWhitelistState,
} = whitelistSlice.actions;

export default whitelistSlice.reducer;
