import React from 'react';
import { useQuery } from 'react-query';
// eslint-disable-next-line import/no-unresolved
import { getMarginCalls } from 'Services/MarginServices';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  CardTitle,
} from 'reactstrap';
import ReactTable from '../Components/ReactTable/ReactTable';

export default function Dashboard() {
  // eslint-disable-next-line no-unused-vars
  const getMarginCallsQuery = useQuery('marginCalls', () => getMarginCalls(), {
    onSuccess: (data) => {
      const marginTableVar = data.map((prop) => ({
        accountnumber: prop.accountnumber,
        accountname: prop.accountname,
        callamount: prop.callamount,
        calltype: prop.calltype,
        tradedate: prop.tradedate,
        duedate: prop.duedate,
        regtdate: prop.regtdate,
        actions: (
          // for some reason the table needs this here so it looks normal
          <div>
            {' '}

          </div>
        ),
      }));

      // eslint-disable-next-line no-use-before-define
      setMarginTable(marginTableVar);
    },
  });

  const [marginTable, setMarginTable] = React.useState(getMarginCallsQuery.data && getMarginCallsQuery.data.map((prop) => ({
    accountnumber: prop.accountnumber,
    accountname: prop.accountname,
    callamount: prop.callamount,
    calltype: prop.calltype,
    tradedate: prop.tradedate,
    duedate: prop.duedate,
    regtdate: prop.regtdate,
    actions: (
      // for some reason the table needs this here so it looks normal
      <div>
        {' '}

      </div>
    ),
  })));

  return (
    <div className="flex h-screen bg-zinc-800">
      <div className="flex justify-center items-start mt-10">
        <header className="App-header justify-self-start mb-3">
          <text className="mb-5">Hedge Admin Dashboard </text>
        </header>
      </div>
      <div className="flex justify-center items-start mt-10">
        <div style={{ marginLeft: '18%', marginRight: '5%' }}>
          <Row className="mt-5" xl={1}>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Margin Calls
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {marginTable && marginTable.length > 0 ? (
                    <ReactTable
                      tableRoute="acats"
                      data={marginTable}
                      filterable
                      resizable={false}
                      columns={[
                        {
                          Header: 'Account Number',
                          accessor: 'accountnumber',
                        },
                        {
                          Header: 'Account Name',
                          accessor: 'accountname',
                        },
                        {
                          Header: 'Margin Call Amount',
                          accessor: 'callamount',
                        },
                        {
                          Header: 'Margin Call Type',
                          accessor: 'calltype',
                        },
                        {
                          Header: 'Trade Date',
                          accessor: 'tradedate',
                        },
                        {
                          Header: 'Due Date',
                          accessor: 'duedate',
                        },
                        {
                          Header: 'Reg T Date',
                          accessor: 'regtdate',
                        },
                        {
                          Header: '',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      defaultPageSize={25}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />

                  ) : (
                    <text> loading </text>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
