/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import {
  Card,
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DiffItem from './components/DiffItem';
import UnmatchedEvents from './UnmatchedEvents';
import { getAllAccountsReconciliation } from '../../Services/ReconciliationServices';

import './TableStyles.css';

function AccountsReconciliation() {
  const navigate = useNavigate();

  const [accountData, setAccountData] = React.useState([]);
  const [expandedSections, setExpandedSections] = React.useState({});

  const toggleSectionExpansion = (rowId, section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [rowId]: {
        ...prevState[rowId],
        [section]: !(prevState[rowId] && prevState[rowId][section]),
      },
    }));
  };

  function handlePress(date, allAccountsReconciliation) {
    navigate(`/admin/accountsReconciliation/${date}`, { state: { allAccountsReconciliation } });
  }

  function handleSODPress(date) {
    navigate(`/admin/accountsReconciliationSOD/${date}`);
  }

  useQuery('getAllAccountsReconciliation', () => getAllAccountsReconciliation(), {
    onSuccess: (data) => {
      const sortedData = data.map((prop) => ({
        apex_total_closed_accounts: prop.apex_total_closed_accounts,
        apex_ext765: (
          <div className="actions-right">
            <Button
              onClick={() => {
                handleSODPress(prop.date);
              }}
              color="#000000"
              size="sm"
            >
              View SOD
            </Button>
          </div>
        ),
        hedge_total_closed_accounts: prop.hedge_total_closed_accounts,
        apex_hedge_difference_in_closed_accounts: JSON.parse(prop.apex_hedge_difference_in_closed_accounts),
        hedge_apex_difference_in_closed_accounts: JSON.parse(prop.hedge_apex_difference_in_closed_accounts),
        apex_total_open_accounts: prop.apex_total_open_accounts,
        hedge_total_open_accounts: prop.hedge_total_open_accounts,
        apex_hedge_difference_in_open_accounts: JSON.parse(prop.apex_hedge_difference_in_open_accounts),
        hedge_apex_difference_in_open_accounts: JSON.parse(prop.hedge_apex_difference_in_open_accounts),
        hedge_dbtotal_count: prop.hedge_dbtotal_count,
        hedge_apex_events_total_count: prop.hedge_apex_events_total_count,
        apex_total_count: prop.apex_total_count,
        date: moment(prop.date).format('YYYY-MM-DD'),
        actions: (
          <div className="actions-right">
            <Button
              disabled={!prop?.all_accounts_reconciliation}
              onClick={() => {
                handlePress(moment(prop.date).format('YYYY-MM-DD'), JSON.parse(prop?.all_accounts_reconciliation));
              }}
              color="#000000"
              size="sm"
            >
              <i className="tim-icons icon-double-right" />
            </Button>
          </div>
        ),
      })).sort((a, b) => moment(b.date).diff(moment(a.date)));
      setAccountData(sortedData);
    },
  });

  const isSectionExpanded = (rowId, section) => expandedSections[rowId] && expandedSections[rowId][section];

  return (
    <div className="flex h-screen bg-zinc-800">
      <div className="flex justify-center items-start mt-10">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">Account Reconciliation</h2>
        </Col>
        <div style={{ marginLeft: '18%', marginRight: '5%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader className="table-header">Total Accounts</CardHeader>
                <CardBody>
                  <table className="styled-table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Apex Total</th>
                        <th>Apex SOD</th>
                        <th>Hedge Total w Status</th>
                        <th>Hedge # Difference</th>
                        <th>Hedge % Difference</th>
                        <th>Hedge RDS Total</th>
                        <th>List View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {accountData && accountData.length > 0 ? (
                        accountData.map((row) => (
                          <tr key={row.date}>
                            <td>{row.date}</td>
                            <td>{row.apex_total_count}</td>
                            <td>{row.apex_ext765}</td>
                            <td>{row.hedge_apex_events_total_count}</td>
                            <td>
                              {row.hedge_dbtotal_count - row.hedge_apex_events_total_count}
                            </td>
                            <td>
                              {(
                                ((row.hedge_dbtotal_count - row.hedge_apex_events_total_count) / row.hedge_dbtotal_count) * 100
                              ).toFixed(2)}
                              %
                            </td>
                            <td>{row.hedge_dbtotal_count}</td>
                            <td>{row.actions}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6">Loading...</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <div style={{ marginLeft: '18%', marginRight: '5%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader className="table-header">Closed Accounts</CardHeader>
                <CardBody>
                  <table className="styled-table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Apex Total Closed</th>
                        <th>Hedge Total Closed</th>
                        <th>Apex/Hedge Closed Diff</th>
                        <th>Hedge/Apex Closed Diff</th>
                      </tr>
                    </thead>
                    <tbody>
                      {accountData && accountData.length > 0 ? (
                        accountData.map((row) => (
                          <React.Fragment key={row.date}>
                            <tr>
                              <td>{row.date}</td>
                              <td>{row.apex_total_closed_accounts}</td>
                              <td>{row.hedge_total_closed_accounts}</td>
                              <td onClick={() => toggleSectionExpansion(row.date, 'apex_closed')}>
                                {row.apex_hedge_difference_in_closed_accounts.length}
                              </td>
                              <td onClick={() => toggleSectionExpansion(row.date, 'hedge_closed')}>
                                {row.hedge_apex_difference_in_closed_accounts.length}
                              </td>
                            </tr>
                            {isSectionExpanded(row.date, 'apex_closed') && (
                              <tr>
                                <td colSpan="6">
                                  <text style={styles.differenceHeaderText}>
                                    Apex-Hedge Closed Account Differences
                                  </text>
                                  {row.apex_hedge_difference_in_closed_accounts.map((account) => (
                                    <DiffItem key={account} accountNumber={account} />
                                  ))}
                                </td>
                              </tr>
                            )}
                            {isSectionExpanded(row.date, 'hedge_closed') && (
                              <tr>
                                <td colSpan="6">
                                  <text style={styles.differenceHeaderText}>
                                    Hedge-Apex Closed Account Differences
                                  </text>
                                  {row.hedge_apex_difference_in_closed_accounts.map((account) => (
                                    <DiffItem key={account} accountNumber={account} />
                                  ))}
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="12">Loading...</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <div style={{ marginLeft: '18%', marginRight: '5%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader className="table-header">Open Accounts</CardHeader>
                <CardBody>
                  <table className="styled-table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Apex Total Open</th>
                        <th>Hedge Total Open</th>
                        <th>Apex/Hedge Open Diff</th>
                        <th>Hedge/Apex Open Diff</th>
                      </tr>
                    </thead>
                    <tbody>
                      {accountData && accountData.length > 0 ? (
                        accountData.map((row) => (
                          <React.Fragment key={row.date}>
                            <tr>
                              <td>{row.date}</td>
                              <td>{row.apex_total_open_accounts}</td>
                              <td>{row.hedge_total_open_accounts}</td>
                              <td onClick={() => toggleSectionExpansion(row.date, 'apex_open')}>
                                {row.apex_hedge_difference_in_open_accounts.length}
                              </td>
                              <td onClick={() => toggleSectionExpansion(row.date, 'hedge_open')}>
                                {row.hedge_apex_difference_in_open_accounts.length}
                              </td>
                            </tr>
                            {isSectionExpanded(row.date, 'apex_open') && (
                              <tr>
                                <td colSpan="6">
                                  <text style={styles.differenceHeaderText}>
                                    Apex-Hedge Open Account Differences
                                  </text>
                                  {row.apex_hedge_difference_in_open_accounts.map((account) => (
                                    <DiffItem key={account} accountNumber={account} />
                                  ))}
                                </td>
                              </tr>
                            )}
                            {isSectionExpanded(row.date, 'hedge_open') && (
                              <tr>
                                <td colSpan="6">
                                  <text style={styles.differenceHeaderText}>
                                    Hedge-Apex Open Account Differences
                                  </text>
                                  {row.hedge_apex_difference_in_open_accounts.map((account) => (
                                    <DiffItem key={account} accountNumber={account} />
                                  ))}
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="12">Loading...</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        {/* New Section for Unmatched Events */}
        <div style={{ marginLeft: '18%', marginRight: '5%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader className="table-header">Unmatched Events</CardHeader>
                <CardBody>
                  <UnmatchedEvents prefix="your-prefix" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

const styles = {
  differenceHeaderText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
};

export default AccountsReconciliation;
