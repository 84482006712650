import React, { useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import ProtectedLayout from './ProtectedLayout';

function PrivateRoute() {
  const { user } = useAuthenticator((context) => [context.user]);
  const location = useLocation();
  const isLocal = process.env.REACT_APP_BUILD_ENV === 'local'; // Check if the environment is local

  useEffect(() => {
    if (user) {
      localStorage.setItem('lastPath', location.pathname);
    }
  }, [user, location.pathname]);

  // Bypass authentication if in local environment
  if (isLocal) {
    return <ProtectedLayout />;
  }

  // Default behavior for non-local environments
  return user ? <ProtectedLayout /> : <Navigate to="/" />;
}

export default PrivateRoute;
