/* eslint-disable no-console */

import AuthenticationService from './Auth.service';

// Set the API URL based on environment
const apiUrl = process.env.REACT_APP_BUILD_ENV === 'preprod'
  ? 'https://yvuldtbwgc.execute-api.us-east-1.amazonaws.com/preprod'
  : process.env.REACT_APP_BUILD_ENV === 'prod'
    ? 'https://i4z79373kk.execute-api.us-east-1.amazonaws.com/prod'
    : 'http://localhost:3000'; // Use local API for 'local' env

// eslint-disable-next-line import/prefer-default-export
export const APIRequestWithAuth = async (type, endpoint, body) => {
  // Skip authentication if in local environment
  let headers = {};

  if (process.env.REACT_APP_BUILD_ENV !== 'local') {
    const { idToken } = await AuthenticationService.getSession();
    const jwt = idToken.toString();
    headers = {
      Authorization: `Bearer ${jwt}`,
    };
  }

  let options;

  if (type === 'GET' || type === 'DELETE') {
    if (body) {
      throw new Error('GET request cannot contain a body');
    }
    options = {
      method: type,
      headers: {
        'Content-Type': 'application/json',
        ...headers, // Include headers based on environment
      },
    };
  } else if (type === 'POST' || type === 'PATCH' || type === 'PUT') {
    options = {
      method: type,
      headers: {
        'Content-Type': 'application/json',
        ...headers, // Include headers based on environment
      },
      body: JSON.stringify(body),
    };
  }

  const request = await fetch(`${apiUrl}${endpoint}`, options);

  const response = await request.json();
  return response;
};
