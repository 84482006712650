/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
} from 'reactstrap';
import { useQuery } from 'react-query';
// eslint-disable-next-line import/no-unresolved
import { getAllOrders } from 'Services/AccountServices';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReactTable from '../Components/ReactTable/ReactTable';

function Orders() {
  const navigate = useNavigate();

  function handlePress(externalId) {
    navigate(`/admin/orders/${externalId}`);
  }

  // eslint-disable-next-line no-unused-vars
  const getAllOrdersQuery = useQuery('getAllOrders', () => getAllOrders(), {
    onSuccess: (data) => {
      // eslint-disable-next-line no-use-before-define
      setData(data.map((prop, key) => ({
        id: key,
        status: prop.status,
        account: prop.account,
        externalId: prop.externalid,
        symbol: prop.symbol,
        side: prop.side,
        shareQuantity: prop.sharequantity,
        avgPrice: prop.avgprice,
        notional: prop.notional,
        uuid: prop.id,
        dateSubmitted: moment(prop.originaldatetime).format('MM/DD/YYYY HH:mm:ss A'),
        lastUpdated: moment(prop.datetime).format('MM/DD/YYYY HH:mm:ss A'),
        actions: (
        // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                handlePress(prop.externalid);
              }}
              color="#000000"
              size="sm"
            >
              <i className="tim-icons icon-double-right" />
            </Button>
            {' '}

          </div>
        ),

      })));
    },
  });

  const [data, setData] = React.useState(getAllOrdersQuery.data && getAllOrdersQuery.data.map((prop, key) => ({
    id: key,
    status: prop.status,
    account: prop.account,
    externalId: prop.externalid,
    symbol: prop.symbol,
    side: prop.side,
    shareQuantity: prop.sharequantity,
    avgPrice: prop.avgprice,
    notional: prop.notional,
    uuid: prop.id,
    dateSubmitted: moment(prop.originaldatetime).format('MM/DD/YYYY HH:mm:ss A'),
    lastUpdated: moment(prop.datetime).format('MM/DD/YYYY HH:mm:ss A'),
    actions: (
    // we've added some custom button actions
      <div className="actions-right">
        {/* use this button to add a edit kind of action */}
        <Button
          onClick={() => {
            handlePress(prop.externalid);
          }}
          color="#000000"
          size="sm"
        >
          <i className="tim-icons icon-double-right" />
        </Button>
        {' '}

      </div>
    ),

  })));

  function handleRefresh() {
    getAllOrdersQuery.refetch();
  }

  return (
    <div className="flex h-screen bg-zinc-800">
      <div className="flex justify-center items-start mt-10">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">Orders</h2>
        </Col>
        <div style={{ marginLeft: '18%', marginRight: '5%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <Button
                    onClick={() => {
                      handleRefresh();
                    }}
                    color="#000000"
                    size="sm"
                  >
                    <i className="tim-icons icon-refresh-01" />
                  </Button>
                  {' '}
                </CardHeader>
                <CardBody>
                  {data && data.length > 0 ? (
                    <ReactTable
                      data={data}
                      filterable
                      resizable={false}
                      columns={[
                        {
                          Header: 'Overall Status',
                          accessor: 'status',
                        },
                        {
                          Header: 'Account',
                          accessor: 'account',
                        },
                        {
                          Header: 'Symbol',
                          accessor: 'symbol',
                        },
                        {
                          Header: 'Side',
                          accessor: 'side',
                        },
                        {
                          Header: 'Share Quantity',
                          accessor: 'shareQuantity',
                        },
                        {
                          Header: 'Avg Price',
                          accessor: 'avgPrice',
                        },
                        {
                          Header: 'Notional',
                          accessor: 'notional',
                        },
                        {
                          Header: 'External ID',
                          accessor: 'externalId',
                        },

                        {
                          Header: 'UUID',
                          accessor: 'uuid',
                        },
                        {
                          Header: 'Date Submitted',
                          accessor: 'dateSubmitted',
                        },
                        {
                          Header: 'Last Updated',
                          accessor: 'lastUpdated',
                        },
                        {
                          Header: 'Details',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  ) : (
                    <text> loading </text>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Orders;
