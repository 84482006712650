import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from './Reducers/session';
import whitelistReducer from './Reducers/whitelist';

// eslint-disable-next-line import/prefer-default-export
export const store = configureStore({
  reducer: {
    session: sessionReducer,
    whitelist: whitelistReducer,
  },
});
