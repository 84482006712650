/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Container, Row, Col, Card, CardBody, CardTitle, Spinner, Alert,
} from 'reactstrap';
import ReactTable from 'Screens/Components/ReactTable/ReactTable';
import { useQuery } from 'react-query';
import GroupInvestingService from '../../../Services/GroupInvestingService';

export default function AccountStatusEventsTable({ groupId }) {
  const accountStatusEventsQuery = useQuery(
    ['groupStatusEvents', groupId],
    () => GroupInvestingService.getAccountStatusEvents(groupId),
    { keepPreviousData: true },
  );

  return (
    <Container fluid className="mt-4">
      <div style={{ marginLeft: '18%', marginRight: '5%' }}>
        <Row className="justify-content-center">
          <Col xs={12} md={12}>
            {accountStatusEventsQuery.isLoading && <Spinner color="primary" />}
            {accountStatusEventsQuery.isError && (
              <Alert color="danger">Error fetching data</Alert>
            )}
            {accountStatusEventsQuery.isSuccess
              && accountStatusEventsQuery.data && (
                <Card className="mb-4">
                  <CardBody>
                    <CardTitle tag="h2">Account Status Events</CardTitle>
                    <ReactTable
                      isSearchActive
                      tableRoute="groups"
                      data={
                        Array.isArray(accountStatusEventsQuery.data)
                          ? accountStatusEventsQuery.data
                          : []
                      }
                      columns={[
                        { Header: 'Account ID', accessor: 'account_id' },
                        {
                          Header: 'Account Status',
                          accessor: 'account_status',
                        },
                        { Header: 'DateTime', accessor: 'datetime' },
                        { Header: 'Account Number', accessor: 'account_num' },
                      ]}
                    />
                  </CardBody>
                </Card>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
}
