/* eslint-disable import/no-unresolved */
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'; // Import Amplify UI styles
import Dashboard from 'Screens/Dashboard/Dashboard';
import Applications from 'Screens/Applications/Applications';
import Accounts from 'Screens/Accounts/Accounts';
import Acats from 'Screens/Acats/Acats';
import Transfers from 'Screens/Transfers/Transfers';
import AchRelationships from 'Screens/AchRelationships/AchRelationships';
import AccountDetails from 'Screens/Accounts/AccountDetails';
import AcatsDetails from 'Screens/Acats/AcatsDetails';
import ApplicationDetails from 'Screens/Applications/ApplicationDetails';
import TransferDetails from 'Screens/Transfers/TransferDetails';
import SanctionsNewsProfilesDetails from 'Screens/Applications/SanctionsNewsProfilesDetails';
import DNDBProfilesDetails from 'Screens/Applications/DNDBProfilesDetails';
import CIPDetails from 'Screens/Applications/CIPDetails';
import Orders from 'Screens/Orders/Orders';
import OrderDetails from 'Screens/Orders/OrderDetails';
import AchRelationshipDetails from 'Screens/AchRelationships/AchRelationshipDetails';
import Alerts from 'Screens/Alerts/Alerts';
import Whitelist from 'Screens/Whitelist/Whitelist';
import AccountsReconciliation from 'Screens/Reconciliation/AccountsReconciliation';
import AccountsReconciliationDetails from 'Screens/Reconciliation/AccountsReconciliationDetails';
import AccountsReconciliationSOD from 'Screens/Reconciliation/AccountsReconciliationSOD';
import UnmatchedEvents from 'Screens/Reconciliation/UnmatchedEvents';
import TickerStatus from 'Screens/TickerStatus/TickerStatus';
import GroupsScreen from 'Screens/Groups/GroupsScreen';
import GroupDetailsScreen from 'Screens/Groups/GroupDetailsScreen';
import GroupWithdrawalDetails from 'Screens/Groups/GroupWithdrawalDetails';
import GroupProposalDetails from './Screens/Groups/GroupProposalDetails';
import PrivateRoute from './Screens/Utils/PrivateRoute';
import LandingPage from './Screens/LandingPage/LandingPage';
import awsmobile from './aws-exports';

const env = process.env.REACT_APP_BUILD_ENV;

let awsconfig;

if (env === 'prod') {
  awsconfig = awsmobile.awsmobileProd;
} else if (env === 'preprod') {
  awsconfig = awsmobile.awsmobilePreProd;
} else if (env === 'local') {
  awsconfig = awsmobile.awsmobileLocal;
}

Amplify.configure(awsconfig);
Amplify.configure(awsconfig);
const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 2, refetchOnWindowFocus: true } },
});

function App() {
  return (
    <Authenticator.Provider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route element={<PrivateRoute />}>
              <Route path="/admin/*" element={<Dashboard />} />
              <Route path="admin/dashboard" element={<Dashboard />} />
              <Route path="admin/applications" element={<Applications />} />
              <Route path="admin/accounts" element={<Accounts />} />
              <Route path="admin/acats" element={<Acats />} />
              <Route path="admin/transfers" element={<Transfers />} />
              <Route
                path="admin/transfers/:externalTransferId"
                element={<TransferDetails />}
              />
              <Route path="admin/orders" element={<Orders />} />
              <Route
                path="admin/orders/:externalId"
                element={<OrderDetails />}
              />
              <Route
                path="admin/achrelationships"
                element={<AchRelationships />}
              />
              <Route
                path="admin/achrelationships/:achRelationshipId"
                element={<AchRelationshipDetails />}
              />
              <Route
                path="admin/accounts/:accountNumber"
                element={<AccountDetails />}
              />
              <Route path="admin/acats/:tifId" element={<AcatsDetails />} />
              <Route
                path="admin/applications/:requestid"
                element={<ApplicationDetails />}
              />
              <Route
                path="admin/applications/:requestid/sanctionsnewsprofiles"
                element={<SanctionsNewsProfilesDetails />}
              />
              <Route
                path="admin/applications/:requestid/dndbprofiles"
                element={<DNDBProfilesDetails />}
              />
              <Route
                path="admin/applications/:requestid/cipdetails"
                element={<CIPDetails />}
              />
              <Route path="admin/alerts" element={<Alerts />} />
              <Route path="admin/whitelist" element={<Whitelist />} />
              <Route path="admin/tickerStatus" element={<TickerStatus />} />
              <Route
                path="admin/accountsReconciliation"
                element={<AccountsReconciliation />}
              />
              <Route
                path="admin/accountsReconciliation/:date"
                element={<AccountsReconciliationDetails />}
              />
              <Route
                path="admin/accountsReconciliationSOD/:date"
                element={<AccountsReconciliationSOD />}
              />
              <Route
                path="admin/events/unmatched"
                element={<UnmatchedEvents />}
              />
              <Route path="admin/groups" element={<GroupsScreen />} />
              <Route
                path="admin/groups/:groupId"
                element={<GroupDetailsScreen />}
              />
              <Route
                path="admin/groups/:groupId/proposals/:proposalId"
                element={<GroupProposalDetails />}
              />
              <Route
                path="admin/groups/:groupId/withdrawals/:withdrawalId"
                element={<GroupWithdrawalDetails />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </Authenticator.Provider>
  );
}

export default App;
