/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { useMutation, useQuery } from 'react-query';
import {
  updateAccountInternalStatus,
  getAccountInternalStatus,
} from 'Services/AccountServices';

const styles = {
  labelStyle: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 20,
    alignItems: 'center',
    color: 'black',
  },
};

// Define a Modal component for updating ticker status
function UpdateAccountStateModal({ modalOpen, setModalOpen, account }) {
  const [frozen, setFrozen] = useState(false);
  const [acatViolator, setAcatViolator] = useState(false);
  const [activeMarginCall, setActiveMarginCall] = useState(false);
  const [amlRestriction, setAmlRestriction] = useState(false);
  const [cashAccount, setCashAccount] = useState(false);
  const [sellOnly, setSellOnly] = useState(false);

  // Personal account controls
  const [submitApplication, setSubmitApplication] = useState(true);
  const [closeAccount, setCloseAccount] = useState(true);
  const [linkBankAccount, setLinkBankAccount] = useState(true);
  const [removeBankAccount, setRemoveBankAccount] = useState(true);
  const [withdrawalHold5, setWithdrawalHold5] = useState(false);
  const [withdrawalHold30, setWithdrawalHold30] = useState(false);
  const [achDepositEnabled, setAchDepositEnabled] = useState(true);
  const [achDepositInstant, setAchDepositInstant] = useState(true);
  const [achWithdrawEnabled, setAchWithdrawEnabled] = useState(true);

  // Group controls
  const [groupsAllowed, setGroupsAllowed] = useState(true);
  const [groupSellVote, setGroupSellVote] = useState(true);
  const [groupSellOrders, setGroupSellOrders] = useState(true);
  const [groupSellReConfirm, setGroupSellReConfirm] = useState(true);
  const [groupSellEnabled, setGroupSellEnabled] = useState(true);
  const [groupSellProposal, setGroupSellProposal] = useState(true);
  const [groupWithdrawalPartial, setGroupWithdrawalPartial] = useState(true);
  const [groupWithdrawalFull, setGroupWithdrawalFull] = useState(true);
  const [groupBuyVote, setGroupBuyVote] = useState(true);
  const [groupBuyOrders, setGroupBuyOrders] = useState(true);
  const [groupBuyReConfirm, setGroupBuyReConfirm] = useState(true);
  const [groupBuyEnabled, setGroupBuyEnabled] = useState(true);
  const [groupBuyProposal, setGroupBuyProposal] = useState(true);
  const [groupDepositVote, setGroupDepositVote] = useState(true);
  const [groupDepositEnabled, setGroupDepositEnabled] = useState(true);
  const [groupDepositProposal, setGroupDepositProposal] = useState(true);

  // Community controls
  const [joinCommunity, setJoinCommunity] = useState(true);
  const [replyToPost, setReplyToPost] = useState(true);
  const [postInCommunities, setPostInCommunities] = useState(true);
  const [createNewPost, setCreateNewPost] = useState(true);

  // Personal order controls
  const [queueOrders, setQueueOrders] = useState(true);
  const [marketSellEnabled, setMarketSellEnabled] = useState(true);
  const [marketSellLimit, setMarketSellLimit] = useState(null);
  const [buyEnabled, setBuyEnabled] = useState(true);
  const [buyLimit, setBuyLimit] = useState(null);
  const [sellEnabled, setSellEnabled] = useState(true);
  const [sellLimit, setSellLimit] = useState(null);
  const [notionalSellEnabled, setNotionalSellEnabled] = useState(true);
  const [notionalSellLimit, setNotionalSellLimit] = useState(null);
  const [limitSellEnabled, setLimitSellEnabled] = useState(true);
  const [limitSellLimit, setLimitSellLimit] = useState(null);
  const [notionalBuyEnabled, setNotionalBuyEnabled] = useState(true);
  const [notionalBuyLimit, setNotionalBuyLimit] = useState(null);
  const [marketBuyEnabled, setMarketBuyEnabled] = useState(true);
  const [marketBuyLimit, setMarketBuyLimit] = useState(null);
  const [limitBuyEnabled, setLimitBuyEnabled] = useState(true);
  const [limitBuyLimit, setLimitBuyLimit] = useState(null);

  // Messaging controls
  const [sendMessageToGroup, setSendMessageToGroup] = useState(true);
  const [sendMessageToNonInvesting, setSendMessageToNonInvesting] = useState(true);
  const [createGroupChat, setCreateGroupChat] = useState(true);
  const [inviteToChat, setInviteToChat] = useState(true);

  const [alert, setAlert] = useState(null);

  const hideAlert = () => {
    setAlert(null);
  };

  const hideOnSuccess = () => {
    setAlert(null);
    setModalOpen(false);
  };

  const accountStateQuery = useQuery(
    ['accountState', account?.user_id],
    () => getAccountInternalStatus(account?.user_name),
    {
      onSuccess: (data) => {
        if (!Object.keys(data).length) return;

        setFrozen(data.frozen ?? false);
        setAcatViolator(data.acat_violator ?? false);
        setActiveMarginCall(data.active_margin_call ?? false);
        setAmlRestriction(data.aml_restriction ?? false);
        setCashAccount(data.cash_account ?? false);
        setSellOnly(data.sell_only ?? false);

        setSubmitApplication(
          data.personal_account_controls?.submit_application ?? true,
        );
        setCloseAccount(data.personal_account_controls?.close_account ?? true);
        setLinkBankAccount(
          data.personal_account_controls?.bank_account_controls
            ?.link_bank_account ?? true,
        );
        setRemoveBankAccount(
          data.personal_account_controls?.bank_account_controls
            ?.remove_bank_account ?? true,
        );
        setWithdrawalHold5(
          data.personal_account_controls?.bank_account_controls
            ?.withdrawal_hold_5d ?? false,
        );
        setWithdrawalHold30(
          data.personal_account_controls?.bank_account_controls
            ?.withdrawal_hold_30d ?? false,
        );
        setAchDepositEnabled(
          data.personal_account_controls?.bank_account_controls?.ach_controls
            ?.deposit?.enabled ?? true,
        );
        setAchDepositInstant(
          data.personal_account_controls?.bank_account_controls?.ach_controls
            ?.deposit?.instant ?? true,
        );
        setAchWithdrawEnabled(
          data.personal_account_controls?.bank_account_controls?.ach_controls
            ?.withdraw?.enabled ?? true,
        );

        setGroupsAllowed(data.group_controls?.groups_allowed ?? true);
        setGroupSellVote(
          data.group_controls?.group_sells?.vote_on_proposal ?? true,
        );
        setGroupSellOrders(data.group_controls?.group_sells?.orders ?? true);
        setGroupSellReConfirm(
          data.group_controls?.group_sells?.re_confirm_vote_on_proposal ?? true,
        );
        setGroupSellEnabled(data.group_controls?.group_sells?.enabled ?? true);
        setGroupSellProposal(
          data.group_controls?.group_sells?.send_proposal ?? true,
        );
        setGroupWithdrawalPartial(
          data.group_controls?.group_withdrawals?.partial_withdrawal ?? true,
        );
        setGroupWithdrawalFull(
          data.group_controls?.group_withdrawals?.full_withdrawal ?? true,
        );
        setGroupBuyVote(
          data.group_controls?.group_buys?.vote_on_proposal ?? true,
        );
        setGroupBuyOrders(data.group_controls?.group_buys?.orders ?? true);
        setGroupBuyReConfirm(
          data.group_controls?.group_buys?.re_confirm_vote_on_proposal ?? true,
        );
        setGroupBuyEnabled(data.group_controls?.group_buys?.enabled ?? true);
        setGroupBuyProposal(
          data.group_controls?.group_buys?.send_proposal ?? true,
        );
        setGroupDepositVote(
          data.group_controls?.group_deposits?.vote_on_proposal ?? true,
        );
        setGroupDepositEnabled(
          data.group_controls?.group_deposits?.enabled ?? true,
        );
        setGroupDepositProposal(
          data.group_controls?.group_deposits?.send_proposal ?? true,
        );

        setJoinCommunity(data.community_controls?.join_community ?? true);
        setReplyToPost(data.community_controls?.reply_to_post ?? true);
        setPostInCommunities(
          data.community_controls?.post_in_communities ?? true,
        );
        setCreateNewPost(data.community_controls?.create_new_post ?? true);

        setQueueOrders(data.personal_order_controls?.queue_orders ?? true);
        setMarketSellEnabled(
          data.personal_order_controls?.market_sell?.enabled ?? true,
        );
        setMarketSellLimit(
          data.personal_order_controls?.market_sell?.limit ?? null,
        );
        setBuyEnabled(data.personal_order_controls?.buy?.enabled ?? true);
        setBuyLimit(data.personal_order_controls?.buy?.limit ?? null);
        setSellEnabled(data.personal_order_controls?.sell?.enabled ?? true);
        setSellLimit(data.personal_order_controls?.sell?.limit ?? null);
        setNotionalSellEnabled(
          data.personal_order_controls?.notional_sell?.enabled ?? true,
        );
        setNotionalSellLimit(
          data.personal_order_controls?.notional_sell?.limit ?? null,
        );
        setLimitSellEnabled(
          data.personal_order_controls?.limit_sell?.enabled ?? true,
        );
        setLimitSellLimit(
          data.personal_order_controls?.limit_sell?.limit ?? null,
        );
        setNotionalBuyEnabled(
          data.personal_order_controls?.notional_buy?.enabled ?? true,
        );
        setNotionalBuyLimit(
          data.personal_order_controls?.notional_buy?.limit ?? null,
        );
        setMarketBuyEnabled(
          data.personal_order_controls?.market_buy?.enabled ?? true,
        );
        setMarketBuyLimit(
          data.personal_order_controls?.market_buy?.limit ?? null,
        );
        setLimitBuyEnabled(
          data.personal_order_controls?.limit_buy?.enabled ?? true,
        );
        setLimitBuyLimit(
          data.personal_order_controls?.limit_buy?.limit ?? null,
        );

        setSendMessageToGroup(
          data.messaging_controls?.send_message?.to_group ?? true,
        );
        setSendMessageToNonInvesting(
          data.messaging_controls?.send_message?.to_non_investing_group_chat
            ?? true,
        );
        setCreateGroupChat(data.messaging_controls?.create_group_chat ?? true);
        setInviteToChat(data.messaging_controls?.invite_to_chat ?? true);
      },
      enabled: !!account?.user_id,
    },
  );

  useEffect(() => {
    accountStateQuery.refetch();
  }, [account]);

  const updateAccountStateMutation = useMutation(updateAccountInternalStatus, {
    onSuccess: () => {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Sent!"
          onConfirm={() => hideOnSuccess()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="success"
          btnSize=""
        >
          Success -
          {' '}
          {account?.user_name}
          {' '}
          internal state updated!
        </ReactBSAlert>,
      );
    },
    onError: (error) => {
      setAlert(
        <ReactBSAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title="Error!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="danger"
          btnSize=""
        >
          {error}
        </ReactBSAlert>,
      );
    },
    onMutate: () => {
      setAlert(
        <ReactBSAlert
          style={{ display: 'block', marginTop: '-100px' }}
          title="Please Wait"
          onConfirm={() => hideAlert()}
          showConfirm={false}
        >
          Making changes...
        </ReactBSAlert>,
      );
    },
  });

  const warningWithConfirmAndCancelMessage = (mutation, body) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure?"
        onConfirm={() => mutation.mutate(body)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes!"
        cancelBtnText="Cancel"
        disabled={mutation.isLoading}
        showCancel
        btnSize=""
      >
        Are you sure you want to update this accounts internal state?
      </ReactBSAlert>,
    );
  };

  return (
    <>
      {alert}
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        size="xl"
      >
        <ModalHeader>
          <h1>
            Update Account State -
            {account?.user_name}
          </h1>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: 20,
            }}
          >
            {/* General account controls */}
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={frozen}
                onChange={(e) => setFrozen(e.target.checked)}
                style={{ color: 'black' }}
              />
              Frozen
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={acatViolator}
                onChange={(e) => setAcatViolator(e.target.checked)}
                style={{ color: 'black' }}
              />
              Acat Violator
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={activeMarginCall}
                onChange={(e) => setActiveMarginCall(e.target.checked)}
                style={{ color: 'black' }}
              />
              Active Margin Call
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={amlRestriction}
                onChange={(e) => setAmlRestriction(e.target.checked)}
                style={{ color: 'black' }}
              />
              AML Restriction
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={cashAccount}
                onChange={(e) => setCashAccount(e.target.checked)}
                style={{ color: 'black' }}
              />
              Cash Account
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={sellOnly}
                onChange={(e) => setSellOnly(e.target.checked)}
                style={{ color: 'black' }}
              />
              Sell Only
            </Label>
            {/* Personal account controls */}
            <h1 style={{ marginTop: '10%' }}>Personal Account Controls</h1>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={submitApplication}
                onChange={(e) => setSubmitApplication(e.target.checked)}
                style={{ color: 'black' }}
              />
              Submit Application
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={closeAccount}
                onChange={(e) => setCloseAccount(e.target.checked)}
                style={{ color: 'black' }}
              />
              Close Account
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={linkBankAccount}
                onChange={(e) => setLinkBankAccount(e.target.checked)}
                style={{ color: 'black' }}
              />
              Link Bank Account
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={removeBankAccount}
                onChange={(e) => setRemoveBankAccount(e.target.checked)}
                style={{ color: 'black' }}
              />
              Remove Bank Account
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={withdrawalHold5}
                onChange={(e) => setWithdrawalHold5(e.target.checked)}
                style={{ color: 'black' }}
              />
              Withdrawal Hold (5 days)
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={withdrawalHold30}
                onChange={(e) => setWithdrawalHold30(e.target.checked)}
                style={{ color: 'black' }}
              />
              Withdrawal Hold (30 days)
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={achDepositEnabled}
                onChange={(e) => setAchDepositEnabled(e.target.checked)}
                style={{ color: 'black' }}
              />
              ACH Deposit Enabled
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={achDepositInstant}
                onChange={(e) => setAchDepositInstant(e.target.checked)}
                style={{ color: 'black' }}
              />
              ACH Deposit Instant
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={achWithdrawEnabled}
                onChange={(e) => setAchWithdrawEnabled(e.target.checked)}
                style={{ color: 'black' }}
              />
              ACH Withdraw Enabled
            </Label>
            {/* Group controls */}
            <h1 style={{ marginTop: '10%' }}>Group Controls</h1>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={groupsAllowed}
                onChange={(e) => setGroupsAllowed(e.target.checked)}
                style={{ color: 'black' }}
              />
              Groups Allowed
            </Label>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h3 style={{ marginTop: 10, color: '#19E421' }}>Group Sells</h3>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupSellVote}
                    onChange={(e) => setGroupSellVote(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Vote on Proposal
                </Label>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupSellOrders}
                    onChange={(e) => setGroupSellOrders(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Orders
                </Label>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupSellReConfirm}
                    onChange={(e) => setGroupSellReConfirm(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Re-confirm Vote on Proposal
                </Label>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupSellEnabled}
                    onChange={(e) => setGroupSellEnabled(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Enabled
                </Label>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupSellProposal}
                    onChange={(e) => setGroupSellProposal(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Send Proposal
                </Label>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h3 style={{ marginTop: 10, color: '#19E421' }}>
                  Group Withdrawals
                </h3>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupWithdrawalPartial}
                    onChange={(e) => setGroupWithdrawalPartial(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Partial Withdrawal
                </Label>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupWithdrawalFull}
                    onChange={(e) => setGroupWithdrawalFull(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Full Withdrawal
                </Label>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h3 style={{ color: '#19E421', marginTop: 10 }}>Group Buys</h3>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupBuyVote}
                    onChange={(e) => setGroupBuyVote(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Vote on Proposal
                </Label>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupBuyOrders}
                    onChange={(e) => setGroupBuyOrders(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Orders
                </Label>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupBuyReConfirm}
                    onChange={(e) => setGroupBuyReConfirm(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Re-confirm Vote on Proposal
                </Label>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupBuyEnabled}
                    onChange={(e) => setGroupBuyEnabled(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Enabled
                </Label>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupBuyProposal}
                    onChange={(e) => setGroupBuyProposal(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Send Proposal
                </Label>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h3 style={{ color: '#19E421', marginTop: 10 }}>
                  Group Deposits
                </h3>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupDepositVote}
                    onChange={(e) => setGroupDepositVote(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Vote on Proposal
                </Label>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupDepositEnabled}
                    onChange={(e) => setGroupDepositEnabled(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Enabled
                </Label>
                <Label
                  check
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 20,
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={groupDepositProposal}
                    onChange={(e) => setGroupDepositProposal(e.target.checked)}
                    style={{ color: 'black' }}
                  />
                  Send Proposal
                </Label>
              </div>
            </div>
            {/* Community controls */}
            <h3 style={{ marginTop: '10%' }}>Community Controls</h3>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={joinCommunity}
                onChange={(e) => setJoinCommunity(e.target.checked)}
                style={{ color: 'black' }}
              />
              Join Community
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={replyToPost}
                onChange={(e) => setReplyToPost(e.target.checked)}
                style={{ color: 'black' }}
              />
              Reply to Post
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={postInCommunities}
                onChange={(e) => setPostInCommunities(e.target.checked)}
                style={{ color: 'black' }}
              />
              Post in Communities
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={createNewPost}
                onChange={(e) => setCreateNewPost(e.target.checked)}
                style={{ color: 'black' }}
              />
              Create New Post
            </Label>
            {/* Personal order controls */}
            <h1 style={{ marginTop: '10%' }}>Personal Order Controls</h1>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={queueOrders}
                onChange={(e) => setQueueOrders(e.target.checked)}
                style={{ color: 'black' }}
              />
              Queue Orders
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={marketSellEnabled}
                onChange={(e) => setMarketSellEnabled(e.target.checked)}
                style={{ color: 'black' }}
              />
              Market Sell Enabled
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="number"
                value={marketSellLimit}
                onChange={(e) => setMarketSellLimit(e.target.value)}
                placeholder="Market Sell Limit"
                style={{ color: 'black' }}
              />
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={buyEnabled}
                onChange={(e) => setBuyEnabled(e.target.checked)}
                style={{ color: 'black' }}
              />
              Buy Enabled
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="number"
                value={buyLimit}
                onChange={(e) => setBuyLimit(e.target.value)}
                placeholder="Buy Limit"
                style={{ color: 'black' }}
              />
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={sellEnabled}
                onChange={(e) => setSellEnabled(e.target.checked)}
                style={{ color: 'black' }}
              />
              Sell Enabled
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="number"
                value={sellLimit}
                onChange={(e) => setSellLimit(e.target.value)}
                placeholder="Sell Limit"
                style={{ color: 'black' }}
              />
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={notionalSellEnabled}
                onChange={(e) => setNotionalSellEnabled(e.target.checked)}
                style={{ color: 'black' }}
              />
              Notional Sell Enabled
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="number"
                value={notionalSellLimit}
                onChange={(e) => setNotionalSellLimit(e.target.value)}
                placeholder="Notional Sell Limit"
                style={{ color: 'black' }}
              />
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={limitSellEnabled}
                onChange={(e) => setLimitSellEnabled(e.target.checked)}
                style={{ color: 'black' }}
              />
              Limit Sell Enabled
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="number"
                value={limitSellLimit}
                onChange={(e) => setLimitSellLimit(e.target.value)}
                placeholder="Limit Sell Limit"
                style={{ color: 'black' }}
              />
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={notionalBuyEnabled}
                onChange={(e) => setNotionalBuyEnabled(e.target.checked)}
                style={{ color: 'black' }}
              />
              Notional Buy Enabled
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="number"
                value={notionalBuyLimit}
                onChange={(e) => setNotionalBuyLimit(e.target.value)}
                placeholder="Notional Buy Limit"
                style={{ color: 'black' }}
              />
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={marketBuyEnabled}
                onChange={(e) => setMarketBuyEnabled(e.target.checked)}
                style={{ color: 'black' }}
              />
              Market Buy Enabled
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="number"
                value={marketBuyLimit}
                onChange={(e) => setMarketBuyLimit(e.target.value)}
                placeholder="Market Buy Limit"
                style={{ color: 'black' }}
              />
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={limitBuyEnabled}
                onChange={(e) => setLimitBuyEnabled(e.target.checked)}
                style={{ color: 'black' }}
              />
              Limit Buy Enabled
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="number"
                value={limitBuyLimit}
                onChange={(e) => setLimitBuyLimit(e.target.value)}
                placeholder="Limit Buy Limit"
                style={{ color: 'black' }}
              />
            </Label>
            {/* Messaging controls */}
            <h1 style={{ marginTop: '10%' }}>Messaging Controls</h1>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={sendMessageToGroup}
                onChange={(e) => setSendMessageToGroup(e.target.checked)}
                style={{ color: 'black' }}
              />
              Send Message to Group
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={sendMessageToNonInvesting}
                onChange={(e) => setSendMessageToNonInvesting(e.target.checked)}
                style={{ color: 'black' }}
              />
              Send Message to Non-Investing Group Chat
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={createGroupChat}
                onChange={(e) => setCreateGroupChat(e.target.checked)}
                style={{ color: 'black' }}
              />
              Create Group Chat
            </Label>
            <Label check style={styles.labelStyle}>
              <Input
                type="checkbox"
                checked={inviteToChat}
                onChange={(e) => setInviteToChat(e.target.checked)}
                style={{ color: 'black' }}
              />
              Invite to Chat
            </Label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={accountStateQuery.isLoading}
            onClick={() => warningWithConfirmAndCancelMessage(updateAccountStateMutation, {
              cognitoId: account?.user_name,
              internalState: {
                frozen,
                acat_violator: acatViolator,
                active_margin_call: activeMarginCall,
                aml_restriction: amlRestriction,
                cash_account: cashAccount,
                sell_only: sellOnly,
                personal_account_controls: {
                  submit_application: submitApplication,
                  close_account: closeAccount,
                  bank_account_controls: {
                    link_bank_account: linkBankAccount,
                    remove_bank_account: removeBankAccount,
                    withdrawal_hold_5d: withdrawalHold5,
                    withdrawal_hold_30d: withdrawalHold30,
                    ach_controls: {
                      deposit: {
                        enabled: achDepositEnabled,
                        instant: achDepositInstant,
                      },
                      withdraw: {
                        enabled: achWithdrawEnabled,
                      },
                    },
                  },
                },
                group_controls: {
                  groups_allowed: groupsAllowed,
                  group_sells: {
                    vote_on_proposal: groupSellVote,
                    orders: groupSellOrders,
                    re_confirm_vote_on_proposal: groupSellReConfirm,
                    enabled: groupSellEnabled,
                    send_proposal: groupSellProposal,
                  },
                  group_withdrawals: {
                    partial_withdrawal: groupWithdrawalPartial,
                    full_withdrawal: groupWithdrawalFull,
                  },
                  group_buys: {
                    vote_on_proposal: groupBuyVote,
                    orders: groupBuyOrders,
                    re_confirm_vote_on_proposal: groupBuyReConfirm,
                    enabled: groupBuyEnabled,
                    send_proposal: groupBuyProposal,
                  },
                  group_deposits: {
                    vote_on_proposal: groupDepositVote,
                    enabled: groupDepositEnabled,
                    send_proposal: groupDepositProposal,
                  },
                },
                community_controls: {
                  join_community: joinCommunity,
                  reply_to_post: replyToPost,
                  post_in_communities: postInCommunities,
                  create_new_post: createNewPost,
                },
                personal_order_controls: {
                  queue_orders: queueOrders,
                  market_sell: {
                    enabled: marketSellEnabled,
                    limit: marketSellLimit,
                  },
                  buy: {
                    enabled: buyEnabled,
                    limit: buyLimit,
                  },
                  sell: {
                    enabled: sellEnabled,
                    limit: sellLimit,
                  },
                  notional_sell: {
                    enabled: notionalSellEnabled,
                    limit: notionalSellLimit,
                  },
                  limit_sell: {
                    enabled: limitSellEnabled,
                    limit: limitSellLimit,
                  },
                  notional_buy: {
                    enabled: notionalBuyEnabled,
                    limit: notionalBuyLimit,
                  },
                  market_buy: {
                    enabled: marketBuyEnabled,
                    limit: marketBuyLimit,
                  },
                  limit_buy: {
                    enabled: limitBuyEnabled,
                    limit: limitBuyLimit,
                  },
                },
                messaging_controls: {
                  send_message: {
                    to_group: sendMessageToGroup,
                    to_non_investing_group_chat: sendMessageToNonInvesting,
                  },
                  create_group_chat: createGroupChat,
                  invite_to_chat: inviteToChat,
                },
              },
            })}
          >
            Save Changes
          </Button>
          <Button color="secondary" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default UpdateAccountStateModal;
