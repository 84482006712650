/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import { useQuery } from 'react-query';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner,
  Button,
} from 'reactstrap';
import GroupInvestingService from 'Services/GroupInvestingService';

export default function WithdrawalDetailsCard({ withdrawal, setWithdrawalData, withdrawalId }) {
  const withdrawalDetailsQuery = useQuery(
    ['withdrawal', withdrawalId],
    () => GroupInvestingService.getWithdrawalDetailsById(withdrawalId),
    {
      onSuccess: (data) => {
        if (data && data.length > 0) {
          setWithdrawalData(data[0]);
        }
      },
    },
  );

  const handleRefresh = () => {
    withdrawalDetailsQuery.refetch();
  };

  return (
    <Card className="bg-dark text-white shadow-lg">
      <CardBody>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1rem',
          }}
        >
          <CardTitle tag="h2" className="text-center mb-4">
            Withdrawal Details
          </CardTitle>
          <Button
            onClick={handleRefresh}
            color="primary"
            size="md"
            style={{
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <i className="tim-icons icon-refresh-01" />
            <p className="mt-2 ml-2">Refresh</p>
            {withdrawalDetailsQuery.isFetching && (
              <Spinner size="sm" color="light" className="ml-2" />
            )}
          </Button>
        </div>
        <Row>
          <Col md="6">
            <p className="h4">
              <strong>Request Date:</strong>
              {' '}
              {new Date(
                withdrawal.requestdatetime
                  || withdrawal.withdrawalrequestdatetime,
              ).toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                fractionalSecondDigits: 3,
              })}
            </p>
            <p className="h4">
              <strong>Last Updated:</strong>
              {' '}
              {new Date(
                withdrawal.lastupdateddatetime
                  || withdrawal.withdrawalrequestlastupdateddatetime,
              ).toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                fractionalSecondDigits: 3,
              })}
            </p>
            <p className="h4">
              <strong>Member Account:</strong>
              {' '}
              {withdrawal.memberaccount}
            </p>
            <p className="h4">
              <strong>Personal Account:</strong>
              {' '}
              {withdrawal.personalaccount}
            </p>
          </Col>
          <Col md="6">
            <p className="h4">
              <strong>Withdrawal Percentage:</strong>
              {' '}
              {(withdrawal.withdrawalpercentage * 100).toFixed(2)}
              %
            </p>
            <p className="h4">
              <strong>State:</strong>
              {' '}
              {withdrawal.withdrawalstate}
            </p>
            <p className="h4">
              <strong>Total Value at Initiation:</strong>
              {' '}
              $
              {parseFloat(withdrawal.totalvalueatinitiation)?.toLocaleString()}
            </p>
            <p className="h4">
              <strong>User ID:</strong>
              {' '}
              {withdrawal.userid}
            </p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
