/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import Modal from './Modal';

function DiffItem({ accountNumber }) {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCheckboxClick = () => {
    setShowModal(true);
  };

  const handleSubmit = () => {
    setIsChecked(true);
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <div style={{ cursor: 'pointer' }} onClick={handleCheckboxClick}>
        {isChecked ? '☑' : '☐'}
        {' '}
      </div>
      <div style={{ marginLeft: '10px' }}>
        {accountNumber}
      </div>
      {showModal && (
        <Modal>
          <div style={{ textAlign: 'center' }}>
            <p>Confirm Action</p>
            <button style={{ backgroundColor: 'red', margin: '10px' }} onClick={handleCancel}>Cancel</button>
            <button style={{ backgroundColor: 'green', margin: '10px' }} onClick={handleSubmit}>Submit</button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default DiffItem;
