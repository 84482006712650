/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import {
  Card,
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';
// eslint-disable-next-line import/no-unresolved
import routes from 'routes.js';
// eslint-disable-next-line import/no-unresolved
import Sidebar from 'Screens/Components/Sidebar/Sidebar';
import './TableStyles.css';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ReactTable from '../Components/ReactTable/ReactTable';

function AccountsReconciliationDetails() {
  const { date } = useParams();
  const location = useLocation();
  const { allAccountsReconciliation } = location.state;
  // for each account number i want it to be a link to the account details page
  const tableData = allAccountsReconciliation.map((prop) => ({
    account_number_action: (
      <div className="actions-right">
        <Button
          onClick={() => {
            handlePress(prop.account_number);
          }}
          color="#000000"
          size="sm"
        >
          {prop.account_number}
          {' '}
          Details
        </Button>
        {' '}

      </div>
    ),
    account_number: prop.account_number,
    account_id: prop.account_id,
    account_type: prop.account_type,
    // if account_group is NaN or null, display 'N/A
    account_group: prop.account_group.toString() === 'NaN' || prop.account_group === null ? 'N/A' : prop.account_group,
    hedge_rds: prop.hedge_rds,
    hedge_status: prop.hedge_status,
    apex_status: prop.apex_status,
    needs_attention: prop.needs_attention,
  }));

  const navigate = useNavigate();

  function handlePress(accountNumber) {
    navigate(`/admin/accounts/${accountNumber}`);
  }

  return (
    <div className="flex h-screen bg-zinc-800">
      <Sidebar
        routes={routes}
        activeColor="blue"
      />
      <div className="flex justify-center items-start mt-10">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">{`Account Reconciliation Details For ${date}`}</h2>
        </Col>
        <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader className="table-header">
                  Total Accounts
                </CardHeader>
                <CardBody>
                  {tableData.length > 0 ? (
                    <ReactTable
                      tableRoute="acctReconDetails"
                      data={tableData}
                      filterable
                      resizable={false}
                      columns={[
                        {
                          Header: 'Account Number',
                          accessor: 'account_number',
                        },
                        {
                          Header: 'Account Number Details',
                          accessor: 'account_number_action',
                          filterable: false,
                        },
                        {
                          Header: 'Account ID',
                          accessor: 'account_id',
                        },
                        {
                          Header: 'Account Type',
                          accessor: 'account_type',
                        },
                        {
                          Header: 'Account Group',
                          accessor: 'account_group',
                        },
                        {
                          Header: 'Hedge RDS',
                          accessor: 'hedge_rds',
                        },
                        {
                          Header: 'Hedge Status',
                          accessor: 'hedge_status',
                        },
                        {
                          Header: 'Apex Status',
                          accessor: 'apex_status',
                        },
                        {
                          Header: 'Needs Attention',
                          accessor: 'needs_attention',
                        },
                        {
                          Header: ' ',
                          accessor: '1',
                        },
                      ]}
                    />
                  ) : (
                    <h2>No Accounts Found</h2>

                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

      </div>
    </div>
  );
}

export default AccountsReconciliationDetails;
