/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Spinner,
} from 'reactstrap';
import ReactTable from 'Screens/Components/ReactTable/ReactTable';
import GroupInvestingService from 'Services/GroupInvestingService';

export default function GroupProposals({ groupId }) {
  const [pageSize, setPageSize] = useState(10); // Set default page size
  const [pageIndex, setPageIndex] = useState(0); // Set default page index

  const proposalsQuery = useQuery(['proposals', groupId], () => GroupInvestingService.getGroupProposalsByGroupId(groupId));

  const columns = useMemo(
    () => [
      {
        Header: 'Proposal ID',
        accessor: 'id',
      },
      {
        Header: 'End Date',
        accessor: 'end_date',
        Cell: ({ value }) => new Date(value).toLocaleDateString(),
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Message',
        accessor: 'message',
      },
      {
        Header: 'Sender ID',
        accessor: 'sender_id',
      },
      {
        Header: 'New Group Proposal',
        accessor: 'new_group_proposal',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
      },
      {
        Header: 'Cash Contribution Proposal',
        accessor: 'cashContributionProposal',
        Cell: ({ row }) => {
          const cashContribution = row.original.Positions.find(
            (position) => position.cash_contribution,
          );
          return cashContribution
            ? `$${parseFloat(
              cashContribution.cash_contribution,
            )?.toLocaleString()}`
            : 'No';
        },
      },
      {
        Header: 'Position Proposal',
        accessor: 'positionProposal',
        Cell: ({ row }) => {
          const position = row.original.Positions.find(
            (position) => position.symbol && position.share_count,
          );
          return position ? `${position.side} ${position.symbol}` : 'No';
        },
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: ({ value }) => new Date(value).toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          fractionalSecondDigits: 3,
        }),
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
        Cell: ({ value }) => new Date(value).toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          fractionalSecondDigits: 3,
        }),
      },
      {
        Header: 'Proposal Details',
        accessor: 'actions',
        Cell: ({ row }) => (
          <div className="actions-right">
            <Link
              to={`/admin/groups/${groupId}/proposals/${row.original.id}`}
              state={{ group: row.original }}
            >
              <i className="tim-icons icon-double-right" />
            </Link>
          </div>
        ),
        sortable: false,
        filterable: false,
        turnFilterOff: true,
      },
    ],
    [groupId],
  );

  const handleRefresh = () => {
    proposalsQuery.refetch();
  };

  return (
    <Card className="bg-dark text-white shadow-lg">
      <CardBody>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <CardTitle tag="h2" className="text-center mb-4">
            Group Proposals
          </CardTitle>
          <Button
            onClick={handleRefresh}
            color="primary"
            size="md"
            style={{
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <i className="tim-icons icon-refresh-01" />
            <p className="mt-2 ml-2">Refresh</p>
            {proposalsQuery.isFetching && (
              <Spinner size="sm" color="light" className="ml-2" />
            )}
          </Button>
        </div>
        {proposalsQuery.isLoading && <div>Loading...</div>}
        {proposalsQuery.isError && <div>Error loading data</div>}
        {proposalsQuery.isSuccess && !proposalsQuery.data && (
          <div>No data found</div>
        )}
        {proposalsQuery.isSuccess && proposalsQuery.data.length && (
          <Row className="mb-3">
            <Col>
              {proposalsQuery.isSuccess && proposalsQuery.data.length > 0 && (
                <ReactTable
                  data={proposalsQuery.data}
                  columns={columns}
                  defaultPageSize={pageSize}
                  page={pageIndex}
                  pageSize={pageSize}
                  pageCount={Math.ceil(proposalsQuery.data.length / pageSize)}
                  onPageChange={setPageIndex}
                  onPageSizeChange={setPageSize}
                />
              )}
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
}
