/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { useQuery } from 'react-query';
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  Button,
  Spinner,
} from 'reactstrap';
import GroupInvestingService from 'Services/GroupInvestingService';
import GroupAumTables from './GroupAumTables';

export default function GroupAumDetails({ groupId }) {
  const groupAumQuery = useQuery(
    ['groupAum', groupId],
    () => GroupInvestingService.getGroupAum(groupId),
    { refetchInterval: false },
  );

  const handleRefresh = () => {
    groupAumQuery.refetch();
  };

  return (
    <Card className="bg-dark text-white shadow-lg p-2">
      <CardBody>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1rem',
          }}
        >
          <CardTitle tag="h2" className="text-center mb-4">
            Group Assets Under Management
          </CardTitle>
          <Button
            onClick={handleRefresh}
            color="primary"
            size="md"
            style={{
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <i className="tim-icons icon-refresh-01" />
            <p className="mt-2 ml-2">Refresh</p>
            {groupAumQuery.isFetching && (
              <Spinner size="sm" color="light" className="ml-2" />
            )}
          </Button>
        </div>
        {groupAumQuery.isLoading && <div>Loading...</div>}
        {groupAumQuery.isError && <div>Error loading data</div>}
        {groupAumQuery.isSuccess && !groupAumQuery.data && (
          <div>No data found</div>
        )}
        {groupAumQuery.isSuccess && groupAumQuery.data && (
          <Row className="mb-3">
            <Col md="6">
              <CardText className="h3">
                <strong>Total Value:</strong>
                {' '}
                {groupAumQuery.data.memberTotalValue?.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </CardText>
              <CardText className="h3">
                <strong>Buying Power:</strong>
                {' '}
                {groupAumQuery.data.memberTotalBuyingPower?.toLocaleString(
                  'en-US',
                  {
                    style: 'currency',
                    currency: 'USD',
                  },
                )}
              </CardText>
            </Col>
            <Col md="6">
              <CardText className="h3">
                <strong>Active Members:</strong>
                {' '}
                {groupAumQuery.data.activeGroupMemberCount}
              </CardText>
              <CardText className="h3">
                <strong>Positions Market Value:</strong>
                {' '}
                {groupAumQuery.data.memberPositionsMarketValue?.toLocaleString(
                  'en-US',
                  {
                    style: 'currency',
                    currency: 'USD',
                  },
                )}
              </CardText>
            </Col>
          </Row>
        )}
      </CardBody>
      <GroupAumTables groupPositions={groupAumQuery.data?.groupPositions} />
    </Card>
  );
}
