/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { useQuery } from 'react-query';
import {
  Card, CardBody, CardTitle, Row, Col,
  Button,
} from 'reactstrap';
import GroupInvestingService from 'Services/GroupInvestingService';
import MasterEventCard from './MasterEventCard';

export default function GroupWithdrawalEventsCard({ withdrawalId }) {
  const masterEventsQuery = useQuery(['withdrawalMasterEvents', withdrawalId], () => GroupInvestingService.getWithdrawalMasterEvents(withdrawalId));
  const handleRefresh = () => {
    masterEventsQuery.refetch();
  };

  const sortedData = masterEventsQuery.data
    ? [...masterEventsQuery.data].sort(
      (a, b) => new Date(b.datetime) - new Date(a.datetime),
    )
    : [];

  return (
    <Card className="bg-dark text-white shadow-lg">
      <CardBody>
        <CardTitle tag="h2" className="text-center mb-4">
          Group Withdrawals
          <Button
            onClick={handleRefresh}
            color="primary"
            size="md"
            style={{
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <i className="tim-icons icon-refresh-01" />
            <p className="mt-2 ml-2">Refresh Master Events</p>
          </Button>

        </CardTitle>
        <Row className="mb-3">
          <Col>
            {masterEventsQuery.isLoading && <div>Loading...</div>}
            {masterEventsQuery.isError && <div>Error loading data</div>}
            {masterEventsQuery.isSuccess
            && sortedData && sortedData.length > 0 && (
              sortedData.map((event) => (
                <MasterEventCard key={event.id} event={event} />
              ))
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
