/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  useTable, useFilters, useSortBy, usePagination,
} from 'react-table';
import classnames from 'classnames';
import Select from 'react-select';
import { FormGroup, Input } from 'reactstrap';

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length - 1;

  return (
    <FormGroup>
      <Input
        type="text"
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    </FormGroup>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // eslint-disable-next-line no-undef
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function ReactTablePaginatedQuery({
  columns,
  data,
  defaultPageSize,
  page,
  pageSize,
  pageCount,
  onPageChange,
  onPageSizeChange,
  isSearchActive,
}) {
  const [pageSelect, handlePageSelect] = React.useState(page);
  const [numberOfRows, setNumberOfRows] = React.useState(pageSize);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
          : true;
      }),
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page: tablePage,
    prepareRow,
    nextPage,
    pageOptions,
    pageCount: tablePageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    state,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: defaultPageSize, pageIndex: page },
      manualPagination: true, // Tell the usePagination hook that we'll handle our own pagination
      pageCount, // This allows the table to handle pagination on the server
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination,
  );

  React.useEffect(() => {
    gotoPage(page);
    handlePageSelect(page); // Update pageSelect state when page prop changes
  }, [page]);

  React.useEffect(() => {
    setPageSize(pageSize);
    setNumberOfRows(pageSize); // Update numberOfRows state when pageSize prop changes
  }, [pageSize]);

  const pageSelectData = Array.from({ length: pageCount }, (_, i) => ({
    value: i,
    label: `Page ${i + 1}`,
  }));

  const numberOfRowsData = [5, 10, 20, 25, 50, 100].map((value) => ({
    value,
    label: `${value} rows`,
  }));

  return (
    <div className="ReactTable -striped -highlight">
      {!isSearchActive && (
        <div className="pagination-top">
          <div className="-pagination">
            <div className="-previous">
              <button
                type="button"
                onClick={() => onPageChange(page - 1)}
                disabled={!canPreviousPage}
                className="-btn"
              >
                Previous
              </button>
            </div>
            <div className="-center flex-nowrap">
              <Select
                className="react-select info mx-5 w-100"
                classNamePrefix="react-select"
                name="singleSelect"
                value={pageSelectData.find(
                  (option) => option.value === pageSelect,
                )}
                onChange={(value) => {
                  onPageChange(value.value);
                  handlePageSelect(value.value);
                }}
                options={pageSelectData}
                placeholder="Select page"
              />
              <Select
                className="react-select info mx-5 w-100"
                classNamePrefix="react-select"
                name="singleSelect"
                value={numberOfRowsData.find(
                  (option) => option.value === numberOfRows,
                )}
                onChange={(value) => {
                  onPageSizeChange(value.value);
                  setNumberOfRows(value.value);
                }}
                options={numberOfRowsData}
                placeholder="Select #rows"
              />
            </div>
            <div className="-next">
              <button
                type="button"
                onClick={() => onPageChange(page + 1)}
                disabled={!canNextPage}
                className="-btn"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
      <table {...getTableProps()} className="rt-table">
        <thead className="rt-thead -header">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={classnames('rt-th rt-resizable-header', {
                    '-sort-asc': column.isSorted && !column.isSortedDesc,
                    '-sort-desc': column.isSorted && column.isSortedDesc,
                  })}
                >
                  <div className="rt-resizable-header-content">
                    {column.render('Header')}
                  </div>
                  {/* Render the columns filter UI */}
                  <div>
                    {column.Header === 'Details'
                      ? null
                      : column.turnFilterOff
                        ? null
                        : column.render('Filter')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="rt-tbody">
          {tablePage.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={classnames(
                  'rt-tr',
                  { ' -odd': i % 2 === 0 },
                  { ' -even': i % 2 === 1 },
                )}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} className="rt-td">
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination-bottom" />
    </div>
  );
}

export default ReactTablePaginatedQuery;
