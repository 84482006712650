/* eslint-disable react/prop-types */
import React from 'react';
import {
  Card, CardBody, CardText, Row, Col, Container, Spinner, Alert,
} from 'reactstrap';
import GroupUtils from '../Group.utils';

export default function ProposalDetailsBox({ proposalQuery }) {
  return (
    <Container fluid className="mt-4">
      {proposalQuery.isLoading && (
      <Spinner color="primary" />
      )}
      {proposalQuery.isError && (
      <Alert color="danger">Error fetching data</Alert>
      )}
      {proposalQuery.isSuccess && proposalQuery.data && (
      <div style={{ marginLeft: '18%', marginRight: '5%' }}>
        <Row className="justify-content-center">
          <Col xs={12} md={12}>
            <Card className="bg-dark text-white shadow-lg">
              <CardBody>
                <Row className="mb-3">
                  <Col md="6">
                    <CardText className="h4">
                      <strong>Proposal Type:</strong>
                      {' '}
                      {GroupUtils.proposalTypeFromProposal(proposalQuery.data) }
                      {' '}
                    </CardText>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      )}
    </Container>
  );
}
