class GroupUtils {
  static PROPOSAL_TYPES = Object.freeze({
    GROUP_FORMATION_PROPOSAL: 'GROUP_FORMATION_PROPOSAL',
    GROUP_DEPOSIT_PROPOSAL: 'GROUP_DEPOSIT_PROPOSAL',
    GROUP_POSITION_PROPOSAL: 'GROUP_POSITION_PROPOSAL',
    COULD_NOT_DETERMINE_PROPOSAL_TYPE: 'COULD_NOT_DETERMINE_PROPOSAL_TYPE',
  });

  static proposalTypeFromProposal = (proposal) => {
    switch (proposal.new_group_proposal) {
      case true:
        return this.PROPOSAL_TYPES.GROUP_FORMATION_PROPOSAL;
      case false:
        if (proposal.Positions.some((position) => position.cash_contribution)) {
          return this.PROPOSAL_TYPES.GROUP_DEPOSIT_PROPOSAL;
        }
        if (proposal.Positions.some((position) => position.symbol)) {
          return this.PROPOSAL_TYPES.GROUP_POSITION_PROPOSAL;
        }
        return this.PROPOSAL_TYPES.COULD_NOT_DETERMINE_PROPOSAL_TYPE;
      default:
        return this.PROPOSAL_TYPES.COULD_NOT_DETERMINE_PROPOSAL_TYPE;
    }
  };
}

export default GroupUtils;
