/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import GroupInvestingService from 'Services/GroupInvestingService';
import { Link } from 'react-router-dom';
import GroupSearch from './components/GroupSearch';
import ReactTablePaginatedQuery from 'Screens/Components/ReactTable/ReactTablePaginatedQuery';

export default function GroupsScreen() {
  const [page, setPage] = useState(0); // react-table uses zero-based index
  const [pageSize, setPageSize] = useState(50);
  const [searchQuery, setSearchQuery] = useState(
    `page=${page + 1}&pageSize=${pageSize}`,
  );

  const [isSearchActive, setIsSearchActive] = useState(false);

  const groupsQuery = useQuery(
    ['groups', searchQuery, page, pageSize],
    () => GroupInvestingService.searchInvestingGroups(
      searchQuery,
    ),
    { keepPreviousData: true },
  );

  const onSearch = (filterType, query) => {
    if (query === '' || filterType === 'all') {
      setSearchQuery(`page=${page + 1}&pageSize=${pageSize}`);
      setIsSearchActive(false);
      groupsQuery.refetch();
      return;
    }
    setSearchQuery(`${filterType}=${query}`);
    setIsSearchActive(true);
    groupsQuery.refetch();
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setSearchQuery(`page=${newPage + 1}&pageSize=${pageSize}`);
    groupsQuery.refetch();
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setSearchQuery(`page=${page + 1}&pageSize=${newPageSize}`);
    groupsQuery.refetch();
  };

  const getNormalizedData = (data) => {
    if (Array.isArray(data)) {
      return data;
    }
    if (data?.data) {
      return data.data;
    }
    return [];
  };

  const normalizedData = getNormalizedData(groupsQuery.data);

  return (
    <div className="flex h-screen bg-zinc-800">
      <div className="flex justify-center items-start mt-10">
        <header className="App-header justify-self-start mb-3">
          <text className="mb-5">Group Investing</text>
        </header>
      </div>
      <div className="flex justify-center items-start mt-10">
        <div
          style={{ marginLeft: '18%', marginRight: '5%', marginBottom: '5%' }}
        >
          <GroupSearch onSearch={onSearch} />
          {groupsQuery.isLoading && <div>Loading...</div>}
          {groupsQuery.isError && <div>Error fetching data</div>}
          {groupsQuery.isSuccess && (
            <ReactTablePaginatedQuery
              tableRoute="groups"
              data={
                normalizedData
              }
              columns={[
                {
                  Header: 'Group Id',
                  accessor: 'id',
                },
                {
                  Header: 'Group Name',
                  accessor: 'group_name',
                },
                {
                  Header: 'Description',
                  accessor: 'description',
                },
                {
                  Header: 'Group Status',
                  accessor: 'status',
                },
                {
                  Header: 'Voting Threshold',
                  accessor: 'voting_threshold',
                },
                {
                  Header: 'Risk Tol.',
                  accessor: 'risk_tolerance',
                },
                {
                  Header: 'Horizon',
                  accessor: 'horizon',
                },
                {
                  Header: 'Min Group Size',
                  accessor: 'group_size_min',
                },
                {
                  Header: 'Max Group Size',
                  accessor: 'group_size_max',
                },
                {
                  Header: 'Created At',
                  accessor: 'createdAt',
                },
                {
                  Header: 'Updated At',
                  accessor: 'updatedAt',
                },
                {
                  Header: 'Group Details',
                  accessor: 'actions',
                  Cell: ({ row }) => (
                    <div className="actions-right">
                      <Link to={`/admin/groups/${row.original.id}`} state={{ group: row.original }}>
                        <i className="tim-icons icon-double-right" />
                      </Link>
                    </div>
                  ),
                  sortable: false,
                  filterable: false,
                  turnFilterOff: true,
                },
              ]}
              defaultPageSize={pageSize}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              page={page}
              pageSize={pageSize}
              pageCount={groupsQuery?.data?.pages || 1} // Pass total page count
              isSearchActive={isSearchActive}
            />
          )}
        </div>
      </div>
    </div>
  );
}
