import { APIRequestWithAuth } from './config';

export const getAllTickersWithAdminStatus = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/tickers/adminMessages');
  if (data) {
    return data;
  }
  return null;
};

export const updateTickerInternalStatus = async (body) => {
  const data = await APIRequestWithAuth(
    'PUT',
    '/v1/tickers/adminMessages',
    body,
  );
  if (data) {
    return data;
  }
  return null;
};
