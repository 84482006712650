/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {
  Card, CardBody, CardTitle, CardText, Row, Col,
} from 'reactstrap';
import GroupInvestingService from 'Services/GroupInvestingService';

export default function GroupDetailsBox({ groupId }) {
  const [groupDetails, setGroupDetails] = useState(null);

  useQuery(
    ['groupDetails', groupId],
    () => GroupInvestingService.searchInvestingGroups(`groupId=${groupId}`),
    {
      onSuccess: (data) => {
        setGroupDetails(data[0]);
      },
    },
  );

  return (
    <Card className="bg-dark text-white shadow-lg">
      { groupDetails === null && <CardBody>Loading...</CardBody>}
      {groupDetails && (

        <CardBody>
          <CardTitle tag="h2" className="text-center mb-4">
            {groupDetails.group_name}
          </CardTitle>
          <Row className="mb-3">
            <Col md="6">
              <CardText className="h4">
                <strong>Description:</strong>
                {' '}
                {groupDetails.description}
              </CardText>
              <CardText className="h4">
                <strong>Voting Threshold:</strong>
                {' '}
                {groupDetails.voting_threshold}
              </CardText>
              <CardText className="h4">
                <strong>Risk Tolerance:</strong>
                {' '}
                {groupDetails.risk_tolerance}
              </CardText>
            </Col>
            <Col md="6">
              <CardText className="h4">
                <strong>Auto Profit or Loss:</strong>
                {' '}
                {groupDetails.auto_profit_or_loss}
              </CardText>
              <CardText className="h4">
                <strong>Status:</strong>
                {' '}
                {groupDetails.status}
              </CardText>
              <CardText className="h4">
                <strong>Horizon:</strong>
                {' '}
                {groupDetails.horizon}
              </CardText>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md="6">
              <CardText className="h4">
                <strong>Group Size Min:</strong>
                {' '}
                {groupDetails.group_size_min}
              </CardText>
              <CardText className="h4">
                <strong>Group Size Max:</strong>
                {' '}
                {groupDetails.group_size_max}
              </CardText>
            </Col>
            <Col md="6">
              <CardText className="h4">
                <strong>Created At:</strong>
                {' '}
                {new Date(groupDetails.createdAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  fractionalSecondDigits: 3,
                })}
              </CardText>
              <CardText className="h4">
                <strong>Updated At:</strong>
                {' '}
                {new Date(groupDetails.updatedAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  fractionalSecondDigits: 3,
                })}
              </CardText>
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>
  );
}
