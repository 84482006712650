/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Collapse,
} from 'reactstrap';
import { useQueryClient } from 'react-query';
import SubEventList from './SubEventList';

export default function MasterEventCard({ event }) {
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const toggle = () => setIsOpen(!isOpen);

  const handleRefresh = () => {
    queryClient.invalidateQueries(['withdrawalSubEvents', event.masterid]);
  };

  return (
    <Card className="bg-dark text-white shadow-lg mb-3">
      <CardBody>
        <CardTitle tag="h4" className="mb-4">
          {event.type}
          {' '}
          -
          {event.state}
        </CardTitle>
        <Row>
          <Col md="6">
            <p className="h4">
              <strong>Amount:</strong>
              {' '}
              {event.amount || 'N/A'}
            </p>
            <p className="h4">
              <strong>Shares:</strong>
              {' '}
              {event.sharequantity || 'N/A'}
            </p>
            <p className="h4">
              <strong>Symbol:</strong>
              {' '}
              {event.symbol || 'N/A'}
            </p>
            <p className="h4">
              <strong>Source Account:</strong>
              {' '}
              {event.sourceaccount}
            </p>
            <p className="h4">
              <strong>Destination Account:</strong>
              {' '}
              {event.destinationaccount}
            </p>
          </Col>
          <Col md="6">
            <p className="h4">
              <strong>State:</strong>
              {' '}
              {event.state}
            </p>
            <p className="h4">
              <strong>Datetime:</strong>
              {' '}
              {new Date(event.datetime).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                fractionalSecondDigits: 3,
              })}
            </p>
            <p className="h4">
              <strong>Master ID:</strong>
              {' '}
              {event.masterid}
            </p>
            <p className="h4">
              <strong>Withdrawal ID:</strong>
              {' '}
              {event.withdrawalid}
            </p>
            <div style={{ display: 'flex', marginBottom: '1rem' }}>
              <Button
                onClick={toggle}
                color="secondary"
                size="md"
                style={{
                  width: 'fit-content',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {isOpen ? 'Hide Sub Events' : 'Show Sub Events'}
              </Button>
              <Button
                onClick={handleRefresh}
                color="primary"
                size="md"
                style={{
                  width: 'fit-content',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <i className="tim-icons icon-refresh-01" />
                <p className="mt-2 ml-2">Refresh Sub Events</p>
              </Button>
            </div>
          </Col>
        </Row>
        <Collapse isOpen={isOpen}>
          <SubEventList masterId={event.masterid} />
        </Collapse>
      </CardBody>
    </Card>
  );
}
