/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from 'react';
import routes from 'routes.js';
import { useParams, useLocation } from 'react-router-dom';
import {
  Col,
} from 'reactstrap';
import moment from 'moment';
import { cancelAchRelationship, createAchWithdrawal } from 'Services/AccountServices';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import SimpleTable from '../Components/SortingTable/SortingTable';
import Sidebar from '../Components/Sidebar/Sidebar';

function ApprovedAchComponent({ achRelationshipId, achRelationshipData }) {
  const [removeRelationshipComment, setRemoveRelationshipComment] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingWithdrawal, setIsLoadingWithdrawal] = React.useState(false);
  const [withdrawalMemo, setWithdrawalMemo] = React.useState('');
  const [withdrawalAmount, setWithdrawalAmount] = React.useState(0);
  const [fullWithdrawal, setFullWithdrawal] = React.useState(false);

  const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);
  const [showFailAlert, setShowFailAlert] = React.useState(false);

  const [showSuccessAlertWithdrawal, setShowSuccessAlertWithdrawal] = React.useState(false);
  const [showFailAlertWithdrawal, setShowFailAlertWithdrawal] = React.useState(false);
  const handleCommentChange = (event) => {
    setRemoveRelationshipComment(event.target.value);
  };
  const handleAmountChange = (event) => {
    setWithdrawalAmount(event.target.value);
  };
  const handleFullWithdrawalChange = () => {
    setFullWithdrawal(!fullWithdrawal);
  };
  const handleMemoChange = (event) => {
    setWithdrawalMemo(event.target.value);
  };

  const handleCancelRelationship = async () => {
    setIsLoading(true);

    const body = {
      relationshipId: achRelationshipId,
      comment: removeRelationshipComment,
    };
    const cancelResponse = await cancelAchRelationship(body);
    if (cancelResponse) {
      setIsLoading(false);
      setShowSuccessAlert(true);
    } else {
      setIsLoading(false);
      setShowFailAlert(true);
    }
  };

  const handleAchWithdrawal = async () => {
    setIsLoadingWithdrawal(true);
    const body = {
      relationshipId: achRelationshipId,
      isFullDisbursement: fullWithdrawal,
      memo: withdrawalMemo,
    };
    if (!fullWithdrawal) {
      // as per apex docs, should be left blank for full disbursement transfers
      // set body.amount to the withdrawalAmount
      body.amount = withdrawalAmount;
    }
    const withdrawalResponse = await createAchWithdrawal(body);
    if (withdrawalResponse) {
      setIsLoadingWithdrawal(false);
      setShowSuccessAlertWithdrawal(true);
    } else {
      setIsLoadingWithdrawal(false);
      setShowFailAlertWithdrawal(true);
    }
  };

  const achRelationship = [
    { label: 'Nickname', value: achRelationshipData?.nickname },
    { label: 'Relationship ID', value: achRelationshipData?.id },
    { label: 'Status', value: achRelationshipData?.status },
    { label: 'Bank Routing Number', value: achRelationshipData?.bankRoutingNumber },
    { label: 'Bank Account', value: achRelationshipData?.bankAccount },
    { label: 'Bank Account Type', value: achRelationshipData?.bankAccountType },
    { label: 'Created On', value: moment(achRelationshipData?.creationTime).format('MM/DD/YYYY HH:mm:ss A') },
    { label: 'Approved On', value: moment(achRelationshipData?.approval?.approvalTime).format('MM/DD/YYYY HH:mm:ss A') },
  ];
  return (
    <>
      <ReactBSAlert
        show={showSuccessAlert}
        title="ACH Relationship Succesfully Cancelled"
        onConfirm={() => setShowSuccessAlert(false)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
      />

      <ReactBSAlert
        show={showFailAlert}
        title="ACH Relationship Cancel Failed"
        onConfirm={() => setShowFailAlert(false)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
      >
        Please Contact Support
      </ReactBSAlert>

      <ReactBSAlert
        show={showSuccessAlertWithdrawal}
        title="Withdrawal Succesfully Created"
        onConfirm={() => setShowSuccessAlertWithdrawal(false)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
      />

      <ReactBSAlert
        show={showFailAlertWithdrawal}
        title="Withdrawal Failed"
        onConfirm={() => setShowFailAlertWithdrawal(false)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
      >
        Please Contact Support
      </ReactBSAlert>

      <div>
        <SimpleTable
          thead={[{ text: 'Label' }, { text: 'Value' }]}
          tbody={achRelationship.map((row) => ({
            data: [
              { text: row.label },
              { text: row.value },
            ],
          }))}
        />

        <div>
          <div style={{ marginBottom: '1%' }}>
            <label style={{ marginRight: '1%', fontSize: 30, marginTop: '1%' }}>
              To cancel this ACH relationship, please add a comment and click the button below to submit your request. Please note, this action is irreversible.
            </label>
          </div>
          <div>
            <input type="text" value={removeRelationshipComment} onChange={handleCommentChange} size="100" />
          </div>
          <div>

            <button style={{ marginTop: '1%' }} type="button" disabled={!removeRelationshipComment || isLoading} onClick={handleCancelRelationship}>{!isLoading ? 'Cancel ACH Relationship' : 'Cancelling...'}</button>

          </div>
        </div>

        <div>
          <div style={{ marginBottom: '1%' }}>
            {/* if not full amount, user has to enter amount */}
            <label style={{ marginRight: '1%', fontSize: 30, marginTop: '5%' }}>
              Create an ACH Withdrawal
            </label>
          </div>
          <div>
            <label style={{ marginRight: '1%', fontSize: 30, marginTop: '1%' }}>
              Withdrawal Full Amount from User Account?
            </label>
            <input type="checkbox" value={fullWithdrawal} onChange={handleFullWithdrawalChange} size="100" />
          </div>
          { !fullWithdrawal && (
            <div>
              <label style={{ marginRight: '1%', fontSize: 30, marginTop: '1%' }}>
                Withdrawal Amount
              </label>
              <input type="number" value={withdrawalAmount} onChange={handleAmountChange} size="30" />
            </div>
          )}

          <div>
            <label style={{ marginRight: '1%', fontSize: 30, marginTop: '1%' }}>
              Withdrawal Memo
            </label>
            <input type="text" value={withdrawalMemo} onChange={handleMemoChange} size="20" maxLength={10} />
          </div>
          <div>
            {/* disabled if there is no memo for a full or partial, no amount for a full, or is loading */}
            <button style={{ marginTop: '1%' }} type="button" disabled={(!fullWithdrawal && (!withdrawalMemo || !withdrawalAmount)) || (fullWithdrawal && !withdrawalMemo) || isLoadingWithdrawal} onClick={handleAchWithdrawal}>{!isLoadingWithdrawal ? 'Submit Withdrawal' : 'Submitting...'}</button>

          </div>
        </div>

      </div>
    </>

  );
}

function CanceledAchComponent({ achRelationshipData }) {
  const achRelationship = [
    { label: 'Nickname', value: achRelationshipData.nickname },
    { label: 'Relationship ID', value: achRelationshipData.id },
    { label: 'Status', value: achRelationshipData.status },
    { label: 'Bank Routing Number', value: achRelationshipData.bankRoutingNumber },
    { label: 'Bank Account', value: achRelationshipData.bankAccount },
    { label: 'Bank Account Type', value: achRelationshipData.bankAccountType },
    { label: 'Created On', value: moment(achRelationshipData.creationTime).format('MM/DD/YYYY HH:mm:ss A') },
    { label: 'Cancelled On', value: moment(achRelationshipData.cancellation.cancellationTime).format('MM/DD/YYYY HH:mm:ss A') },
    { label: 'Cancellation Reason', value: achRelationshipData.cancellation.reason },
    { label: 'Cancelled By', value: achRelationshipData.cancellation.cancelledBy.name },
  ];
  return (
    <SimpleTable
      thead={[{ text: 'Label' }, { text: 'Value' }]}
      tbody={achRelationship.map((row) => ({
        data: [
          { text: row.label },
          { text: row.value },
        ],
      }))}
    />
  );
}

function RejectedAchComponent({ achRelationshipData }) {
  const achRelationship = [
    { label: 'Nickname', value: achRelationshipData.nickname },
    { label: 'Relationship ID', value: achRelationshipData.id },
    { label: 'Status', value: achRelationshipData.status },
    { label: 'Bank Routing Number', value: achRelationshipData.bankRoutingNumber },
    { label: 'Bank Account', value: achRelationshipData.bankAccount },
    { label: 'Bank Account Type', value: achRelationshipData.bankAccountType },
    { label: 'Created On', value: moment(achRelationshipData.creationTime).format('MM/DD/YYYY HH:mm:ss A') },
    { label: 'Rejected On', value: moment(achRelationshipData.rejection.rejectionTime).format('MM/DD/YYYY HH:mm:ss A') },
    { label: 'Rejection Requested By', value: achRelationshipData.rejection.requestedBy.name },
  ];
  const rejectionReasons = [];

  // eslint-disable-next-line array-callback-return
  achRelationshipData.rejection.rejectionReasons.errorList.map((error) => {
    rejectionReasons.push({ label: error.errorCode, value: error.errorDescription });
  });
  return (
    <>
      <SimpleTable
        thead={[{ text: 'Label' }, { text: 'Value' }]}
        tbody={achRelationship.map((row) => ({
          data: [
            { text: row.label },
            { text: row.value },
          ],
        }))}
      />
      <SimpleTable
        thead={[{ text: 'Error Code' }, { text: 'Error Description' }]}
        tbody={rejectionReasons.map((row) => ({
          data: [
            { text: row.label },
            { text: row.value },
          ],
        }))}
      />
    </>
  );
}

export default function AchRelationshipDetails() {
  const { achRelationshipId } = useParams();

  const location = useLocation();
  let { achRelationshipData } = location.state;
  // //only want the achRelationshipData for the achRelationshipId
  // eslint-disable-next-line prefer-destructuring
  achRelationshipData = achRelationshipData?.length === 1 ? achRelationshipData[0] : achRelationshipData.filter((achRelationship) => achRelationship.id === achRelationshipId)[0];
  return (
    <div className="flex h-screen bg-zinc-800">
      <Sidebar
        routes={routes}
        activeColor="blue"
      />
      <div className="flex justify-center items-start mt-10">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">
            ACH Relationship Details for
            {' '}
            {achRelationshipId}
          </h2>
        </Col>

        <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
          {
            achRelationshipData && achRelationshipData.status === 'APPROVED'
            && <ApprovedAchComponent achRelationshipId={achRelationshipId} achRelationshipData={achRelationshipData} />
        }
          {
            achRelationshipData && achRelationshipData.status === 'CANCELED'
            && <CanceledAchComponent achRelationshipId={achRelationshipId} achRelationshipData={achRelationshipData} />
        }
          {
            achRelationshipData && achRelationshipData.status === 'REJECTED'
            && <RejectedAchComponent achRelationshipId={achRelationshipId} achRelationshipData={achRelationshipData} />
        }

        </div>
      </div>
    </div>
  );
}
