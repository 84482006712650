/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { Button } from 'reactstrap';

export default function SearchBar({
  onSearch, setSearching, whitelistQuery, setChartData,
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Backspace' && e.target.value.length === 0) {
      setSearching(false);
      setChartData(whitelistQuery.data);
      return;
    }
    setSearching(true);
    handleSearch();
  };

  return (
    <div
      className="search-bar"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '20px',
      }}
    >
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleInputChange}
        onKeyUp={handleKeyPress}
        style={{
          width: '80%',
          borderRadius: '20px',
          padding: '10px',
        }}
      />
      <Button
        onClick={handleSearch}
        color="primary"
      >
        Search
      </Button>
    </div>
  );
}
