/* eslint-disable no-console */
import axios from 'axios';
import { APIRequestWithAuth } from './config';
import AuthenticationService from './Auth.service';

export const getApexAccountDetails = async (accountNum) => {
  const data = await APIRequestWithAuth('GET', `/v1/getApexAccountDetails/${accountNum}`);
  if (data) {
    return data;
  }
  return null;
};

export const getPdtHistory = async (accountNum) => {
  const data = await APIRequestWithAuth('GET', `/v1/getPdtHistory/${accountNum}`);
  if (data) {
    return data;
  }
  return null;
};

export const getApexInvestigationDetails = async (requestId) => {
  const data = await APIRequestWithAuth('GET', `/v1/getInvestigationDetails/${requestId}`);
  if (data) {
    return data;
  }
  return null;
};

export const getAllApexWithSketch = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/getAllApexWithSketch');
  if (data) {
    return data;
  }
  return null;
};

export const getAllTransfers = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/getTransfers');
  if (data) {
    return data;
  }
  return null;
};

export const getAllAdjustments = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/getAdjustments');
  if (data) {
    return data;
  }
  return null;
};

export const getTransferHistoryById = async (externalId) => {
  const data = await APIRequestWithAuth('GET', `/v1/getTransferHistoryByExternalId/${externalId}`);
  if (data) {
    return data;
  }
  return null;
};

export const getAllOrders = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/getOrders');
  if (data) {
    return data;
  }
  return null;
};

export const getOrderHistoryById = async (externalId) => {
  const data = await APIRequestWithAuth('GET', `/v1/getOrderHistoryByExternalId/${externalId}`);
  if (data) {
    return data;
  }
  return null;
};

export const getAllOrdersTransfersByAcct = async (accountNum) => {
  const data = await APIRequestWithAuth('GET', `/v1/getOrdersTransfersByAccount/${accountNum}`);
  if (data) {
    return data;
  }
  return null;
};

export const getAllAchRelationships = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/getAchRelationships');
  if (data) {
    return data;
  }
  return null;
};

export const uploadApexDocument = async (body) => {
  const data = await APIRequestWithAuth('POST', '/v1/uploadApexInvestigationDocument', body);
  if (data) {
    return data;
  }
  return null;
};

export const cancelAchRelationship = async (body) => {
  const data = await APIRequestWithAuth('POST', '/v1/cancelAchRelationship', body);
  if (data) {
    return data;
  }
  return null;
};

export const createAchWithdrawal = async (body) => {
  const data = await APIRequestWithAuth('POST', '/v1/createAchWithdrawal', body);
  if (data) {
    return data;
  }
  return null;
};

export const cancelAchWithdrawal = async (body) => {
  const data = await APIRequestWithAuth('POST', '/v1/cancelAchWithdrawal', body);
  if (data) {
    return data;
  }
  return null;
};

export const cancelAchDeposit = async (body) => {
  const data = await APIRequestWithAuth('POST', '/v1/cancelAchDeposit', body);
  if (data) {
    return data;
  }
  return null;
};

export const getAchRelationshipById = async (id) => {
  const data = await APIRequestWithAuth('GET', `/v1/getAchRelationship/${id}`);
  if (data) {
    return data;
  }
  return null;
};

export const getInternalInvestigationHistory = async (accountNum) => {
  const data = await APIRequestWithAuth('GET', `/v1/getInternalInvestigationHistory/${accountNum}`);
  if (data) {
    return data;
  }
  return null;
};

export const getIdentityDocsByAccountNumber = async (accountNum) => {
  const data = await APIRequestWithAuth('GET', `/v1/identity/docs/${accountNum}`);
  if (data) {
    return data;
  }
  return null;
};

export const getUserInfoByApexAccountNumber = async (accountNum) => {
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/identity/user/${accountNum}`,
  );
  if (data) {
    return data;
  }
  return null;
};

export const updateInternalInvestigationState = async (body) => {
  const data = await APIRequestWithAuth(
    'POST',
    '/v1/identity/internalInvestigationEvent',
    body,
  );
  if (data) {
    return data;
  }
  return null;
};

export const manuallyApproveIndeterminateInvestigation = async (body) => {
  const data = await APIRequestWithAuth(
    'POST',
    '/v1/identity/autoApproveApexApplication',
    body,
  );
  if (data) {
    return data;
  }
  return null;
};

export const searchUsers = async (searchTerm) => {
  try {
    const { idToken } = await AuthenticationService.getSession();
    const jwt = idToken.toString();
    if (!jwt) {
      console.error('JWT token not found');
      return null;
    }

    const headers = {
      Authorization: `Bearer ${jwt}`,
    };

    const response = await axios.get(
      `${
        process.env.REACT_APP_OPENSEARCH_ENDPOINT
      }/users/search?searchTerm=${encodeURIComponent(searchTerm)}`,
      { headers },
    );
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.error('Error fetching users:', error);
    return null;
  }
};

export const updateAccountInternalStatus = async (body) => {
  const data = await APIRequestWithAuth(
    'PUT',
    '/v1/accounts/internalState',
    body,
  );
  if (data) {
    return data;
  }
  return null;
};

export const getAccountInternalStatus = async (cognito) => {
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/accounts/internalState/${cognito}`,
  );
  if (data) {
    return data;
  }
  return null;
};
