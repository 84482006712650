/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import ProposalDetailsTable from './components/ProposalDetailsTable';
import GroupInvestingService from '../../Services/GroupInvestingService';
import ProposalDetailsBox from './components/proposalDetailsBox';
import GroupUtils from './Group.utils';
import AccountStatusEventsTable from './components/AccountStatusEventsTable';
import MemberAccountsTable from './components/MemberAccountsTable';
import GroupTransfersTable from './components/GroupTransfersTable';
import GroupOrdersTable from './components/GroupOrdersTable';
import GroupAllocationsTable from './components/GroupAllocationsTable';

export default function GroupProposalDetails() {
  const { proposalId } = useParams();

  const proposalQuery = useQuery(
    ['proposalDetail', proposalId],
    () => GroupInvestingService.getProposalById(proposalId),
    { keepPreviousData: true },
  );

  const newGroupFormationProposalComponents = () => (
    <>
      <AccountStatusEventsTable groupId={proposalQuery.data.investing_group_id} />
      <MemberAccountsTable groupId={proposalQuery.data.investing_group_id} />
      <GroupTransfersTable proposalId={proposalQuery.data.id} />
    </>
  );

  const depositProposalComponents = () => (
    <GroupTransfersTable proposalId={proposalQuery.data.id} />
  );

  const positionProposalComponents = () => (
    <>
      <GroupOrdersTable proposalId={proposalQuery.data.id} />
      <GroupAllocationsTable proposalId={proposalQuery.data.id} />
    </>
  );

  // eslint-disable-next-line no-shadow
  const renderProposalComponents = (proposalQuery) => {
    if (proposalQuery.isLoading) {
      return <div>Loading...</div>;
    }
    if (proposalQuery.isError) {
      return <div>Error fetching data</div>;
    }

    switch (GroupUtils.proposalTypeFromProposal(proposalQuery.data)) {
      case GroupUtils.PROPOSAL_TYPES.GROUP_FORMATION_PROPOSAL:
        return newGroupFormationProposalComponents();
      case GroupUtils.PROPOSAL_TYPES.GROUP_DEPOSIT_PROPOSAL:
        return depositProposalComponents();
      case GroupUtils.PROPOSAL_TYPES.GROUP_POSITION_PROPOSAL:
        return positionProposalComponents();
      default:
        return <div>Unknown proposal type</div>;
    }
  };

  return (
    <div className="flex h-screen bg-zinc-800">
      <div className="flex justify-center items-start mt-10">
        <header className="App-header justify-self-start mb-3">
          <text className="mb-5">Group Investing</text>
        </header>

        <ProposalDetailsBox proposalQuery={proposalQuery} />
        <ProposalDetailsTable proposalQuery={proposalQuery} />
        {renderProposalComponents(proposalQuery)}
      </div>
    </div>
  );
}
