/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React, { useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { useQuery } from 'react-query';
import { getApexDiff } from 'Services/WhitelistService';
import { useDispatch } from 'react-redux';
import {
  addStockToAdditions, removeStockFromAdditions, addStockToRemovals, removeStockFromRemovals,
} from 'store/Reducers/whitelist';
import { Button } from 'reactstrap';
import TableStats from './TableStats';

export default function ApexDiffTable() {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState([]);
  const [dataType, setDataType] = useState('ADD');
  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {
      field: 'ticker',
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));

  const apexDiffQuery = useQuery('apexDiff', () => getApexDiff(), {
    onSuccess: (data) => {
      if (dataType === 'ADD') {
        setChartData(data.symbolsToAdd.map((symbol) => ({ ticker: symbol })));
        setColumnDefs((prev) => {
          prev[0].field = 'ticker';
          return prev;
        });
      } else {
        setChartData(data.symbolsToRemove.map((symbol) => ({ symbol })));
        setColumnDefs((prev) => {
          prev[0].field = 'symbol';
          return prev;
        });
      }
    },
  });

  const icons = useMemo(
    () => ({
      'custom-stats': '<span class="ag-icon ag-icon-custom-stats"></span>',
    }),
    [],
  );

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'customStats',
          labelDefault: 'Stats',
          labelKey: 'customStats',
          iconKey: 'custom-stats',
          toolPanel: TableStats,
        },
      ],
      defaultToolPanel: 'customStats',
    }),
    [],
  );

  const handleChangePress = (e) => {
    setDataType(dataType === 'ADD' ? 'REMOVE' : 'ADD');
    apexDiffQuery.refetch();
  };

  const onCellClicked = (e) => {
    const { data, node } = e;
    if (dataType === 'ADD') {
      if (node.selected) dispatch(addStockToAdditions(data));
      else dispatch(removeStockFromAdditions(data));
    } else if (dataType === 'REMOVE') {
      if (node.selected) dispatch(addStockToRemovals(data));
      else dispatch(removeStockFromRemovals(data));
    }
  };

  const handleRefresh = async () => {
    await apexDiffQuery.refetch();
  };
  return (
    <div style={{ height: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <h1>Apex Diff</h1>
        <Button onClick={handleRefresh} size="md" style={{ marginBottom: '4%', marginLeft: '3%' }}>
          Refresh
        </Button>
      </div>
      <h2>
        Symbols to
        <span style={{ fontWeight: 'bold' }}>
          {' '}
          {dataType}
        </span>
      </h2>
      <button
        style={{ borderRadius: 20, padding: 10, marginBottom: 5 }}
        onClick={handleChangePress}
      >
        Switch to symbols to
        {' '}
        {dataType === 'ADD' ? 'REMOVE' : 'ADD'}
      </button>
      <AgGridReact
        className="ag-theme-alpine"
        ref={gridRef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows
        rowSelection="multiple"
        onCellClicked={onCellClicked}
        rowData={chartData}
        sideBar={sideBar}
        icons={icons}
        rowMultiSelectWithClick
      />
    </div>
  );
}
