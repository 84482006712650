import { APIRequestWithAuth } from './config';

export const getAllAcats = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/getAcats');
  if (data) {
    return data;
  }
  return null;
};

export const getAcatsByTifId = async (tifId) => {
  const data = await APIRequestWithAuth('GET', `/v1/getAcatsByTifId/${tifId}`);
  if (data) {
    return data;
  }
  return null;
};

export const getAcatsDetails = async (acatsControlNumber) => {
  const data = await APIRequestWithAuth('GET', `/v1/getAcatsDetails/${acatsControlNumber}`);
  if (data) {
    return data;
  }
  return null;
};
