/* eslint-disable import/no-unresolved */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import AuthenticationService from 'Services/Auth.service';

const initialState = {
  email: null,
};

// Helper functions to handle localStorage
const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('session', serializedState);
  } catch (e) {
    console.warn('Could not save state', e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('session');
    if (serializedState === null) return initialState;
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn('Could not load state', e);
    return initialState;
  }
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState: loadFromLocalStorage(),
  reducers: {
    login: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const { attributes } = action.payload;
      state.email = attributes.email;
      saveToLocalStorage(state); // Save to local storage
    },
    logout: (state) => {
      state.email = null;
      localStorage.removeItem('session'); // Remove from local storage
    },
  },
});

export const { login, logout } = sessionSlice.actions;

export const updateSession = () => async (dispatch) => {
  const { idToken } = await AuthenticationService.getSession();
  const jwt = idToken.toString();
  dispatch(login(jwt));
};

export default sessionSlice.reducer;
