/* eslint-disable global-require */
/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-alert */
/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Authenticator,
  useAuthenticator,
  ThemeProvider,
  createTheme,
  useTheme,
  View,
  Image,
  Heading,
  Text,
  Button,
} from '@aws-amplify/ui-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AuthenticationService from 'Services/Auth.service';
import { updateSession } from 'store/Reducers/session';
import '@aws-amplify/ui-react/styles.css'; // Import Amplify UI styles
import './App.css'; // Import custom CSS for alignment

const customTheme = createTheme({
  name: 'custom-theme',
  tokens: {
    colors: {
      background: {
        primary: { value: '#121212' }, // Dark background
        secondary: { value: '#1e1e1e' }, // Slightly lighter dark background
      },
      font: {
        primary: { value: '#ffffff' }, // White text
        secondary: { value: '#e0e0e0' }, // Slightly lighter white text
      },
      brand: {
        primary: {
          10: { value: '#ff9900' }, // Your primary brand color
          100: { value: '#ff9900' }, // Your primary brand color
        },
      },
    },
    components: {
      button: {
        primary: {
          backgroundColor: { value: '#19E421' }, // Primary button background color
          color: { value: '#121212' }, // Primary button text color
        },
      },
    },
  },
});

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Company logo"
          src={require('../../assets/img/hedge-logo-gradient.png')} // Replace with your logo URL
          width="200px"
        />
      </View>
    );
  },
  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },
};

export default function LandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totpSetupUri, setTotpSetupUri] = useState(null);
  const [totpCode, setTotpCode] = useState('');
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    const handleSignIn = async () => {
      if (user) {
        const { nextStep } = user.challengeName
          ? await AuthenticationService.handleSignIn({
            email: user.username,
            password: user.signInUserSession.idToken.jwtToken,
          })
          : { nextStep: null };

        if (nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') {
          return;
        }

        if (nextStep?.signInStep === 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP') {
          const setupUri = await AuthenticationService.enableTOTP();
          setTotpSetupUri(setupUri);
          return;
        }

        dispatch(updateSession());
        const lastPath = localStorage.getItem('lastPath') || '/admin/dashboard';
        navigate(lastPath);
      }
    };

    handleSignIn();
  }, [user, dispatch, navigate]);

  const handleTotpSubmit = async () => {
    try {
      await AuthenticationService.verifyTOTP(totpCode);
      setTotpSetupUri(null);
      dispatch(updateSession());
      const lastPath = localStorage.getItem('lastPath') || '/admin/dashboard';
      navigate(lastPath);
    } catch (error) {
      console.error('Error verifying TOTP: ', error);
      alert('Error verifying TOTP');
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div className="landing-page-container">
        <div className="authenticator-container">
          <Authenticator components={components} hideSignUp>
            {({ user }) => {
              if (user) {
                if (totpSetupUri) {
                  return (
                    <div>
                      <h1>Set up TOTP</h1>
                      <p>Scan this QR code with your authenticator app:</p>
                      <img
                        src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
                          totpSetupUri,
                        )}`}
                        alt="TOTP QR Code"
                      />
                      <input
                        type="text"
                        value={totpCode}
                        onChange={(e) => setTotpCode(e.target.value)}
                        placeholder="Enter TOTP Code"
                      />
                      <button onClick={handleTotpSubmit}>Submit</button>
                    </div>
                  );
                }

                return (
                  <main>
                    <h1>
                      Hello
                      {user.username}
                    </h1>
                    <button onClick={signOut}>Sign out</button>
                  </main>
                );
              }

              return null;
            }}
          </Authenticator>
        </div>
      </div>
    </ThemeProvider>
  );
}
