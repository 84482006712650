/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Container, Row, Col, Card, CardBody, CardTitle, Spinner, Alert,
} from 'reactstrap';
import ReactTable from 'Screens/Components/ReactTable/ReactTable';
import { useQuery } from 'react-query';
import GroupInvestingService from '../../../Services/GroupInvestingService';

export default function GroupTransfersTable({ proposalId }) {
  const groupTransferEventsQuery = useQuery(
    ['groupTransfers', proposalId],
    () => GroupInvestingService.getGroupTransferEvents(proposalId),
    { keepPreviousData: true },
  );

  return (
    <Container fluid className="mt-4">
      <div style={{ marginLeft: '18%', marginRight: '5%' }}>
        <Row className="justify-content-center">
          <Col xs={12} md={12}>
            {groupTransferEventsQuery.isLoading && <Spinner color="primary" />}
            {groupTransferEventsQuery.isError && (
              <Alert color="danger">Error fetching data</Alert>
            )}
            {groupTransferEventsQuery.isSuccess
              && groupTransferEventsQuery.data && (
                <Card className="mb-4">
                  <CardBody>
                    <CardTitle tag="h2">Group Transfer Events</CardTitle>
                    <ReactTable
                      isSearchActive
                      tableRoute="groups"
                      data={
                        Array.isArray(groupTransferEventsQuery.data)
                          ? groupTransferEventsQuery.data
                          : []
                      }
                      columns={[
                        { Header: 'ID', accessor: 'id' },
                        { Header: 'Amount', accessor: 'amount' },
                        { Header: 'Timestamp', accessor: 'timestamp' },
                        { Header: 'Transfer ID', accessor: 'transferid' },
                        {
                          Header: 'External Transfer ID',
                          accessor: 'externaltransferid',
                        },
                        { Header: 'Type', accessor: 'type' },
                        { Header: 'State', accessor: 'state' },
                        { Header: 'DateTime', accessor: 'datetime' },
                        { Header: 'Source Account', accessor: 'sourceaccount' },
                        {
                          Header: 'Destination Account',
                          accessor: 'destinationaccount',
                        },
                      ]}
                    />
                  </CardBody>
                </Card>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
}
