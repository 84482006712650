/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { useMutation } from 'react-query';
import { updateTickerInternalStatus } from 'Services/TickerService';

// Define a Modal component for updating ticker status
function UpdateTickerModal({
  modalOpen, setModalOpen, ticker,
  getAllTickersQuery,
}) {
  const [internalAvailableForTrade, setInternalAvailableForTrade] = useState(true);
  const [internalSellOnly, setInternalSellOnly] = useState(false);
  const [internalFractionalAllowed, setInternalFractionalAllowed] = useState(true);
  const [alert, setAlert] = useState(null);

  const hideAlert = () => {
    setAlert(null);
  };

  const hideOnSuccess = () => {
    setAlert(null);
    setModalOpen(false);
  };

  useEffect(() => {
    // Set to existing value if it exists, otherwise default to true
    setInternalAvailableForTrade(
      ticker?.internalAvailableForTrade?.BOOL !== undefined
        ? ticker.internalAvailableForTrade.BOOL
        : true,
    );
    setInternalSellOnly(
      ticker?.internalSellOnly?.BOOL !== undefined
        ? ticker.internalSellOnly.BOOL
        : false,
    );
    setInternalFractionalAllowed(
      ticker?.internalFractionalAllowed?.BOOL !== undefined
        ? ticker.internalFractionalAllowed.BOOL
        : true,
    );
  }, [ticker]);

  const updateTickerStatusMutation = useMutation(
    updateTickerInternalStatus,
    {
      onSuccess: () => {
        getAllTickersQuery.refetch();
        setAlert(
          <ReactBSAlert
            success
            style={{ display: 'block', marginTop: '-100px' }}
            title="Sent!"
            onConfirm={() => hideOnSuccess()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            Success - Ticker status updated!
          </ReactBSAlert>,
        );
      },
      onError: (error) => {
        setAlert(
          <ReactBSAlert
            danger
            style={{ display: 'block', marginTop: '-100px' }}
            title="Error!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="danger"
            btnSize=""
          >
            {error}
          </ReactBSAlert>,
        );
      },
      onMutate: () => {
        setAlert(
          <ReactBSAlert
            style={{ display: 'block', marginTop: '-100px' }}
            title="Please Wait"
            onConfirm={() => hideAlert()}
            showConfirm={false}
          >
            Making changes...
          </ReactBSAlert>,
        );
      },
    },
  );

  const warningWithConfirmAndCancelMessage = (
    mutation,
    body,
  ) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure?"
        onConfirm={() => mutation.mutate(body)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes!"
        cancelBtnText="Cancel"
        disabled={mutation.isLoading}
        showCancel
        btnSize=""
      >
        Are you sure you want to update the ticker status?
      </ReactBSAlert>,
    );
  };

  return (
    <>
      {alert}
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
        <ModalHeader>
          <h1>
            Update Ticker Status -
            {ticker?.symbol?.S}
          </h1>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: 20,
            }}
          >
            <Label
              check
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: 20,
                alignItems: 'center',
              }}
            >
              <Input
                type="checkbox"
                checked={internalAvailableForTrade}
                onChange={(e) => setInternalAvailableForTrade(e.target.checked)}
              />
              {' '}
              Internal Available For Trade
            </Label>
            <Label
              check
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: 20,
                alignItems: 'center',
              }}
            >
              <Input
                type="checkbox"
                checked={internalSellOnly}
                onChange={(e) => setInternalSellOnly(e.target.checked)}
              />
              {' '}
              Internal Sell Only
            </Label>
            <Label
              check
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: 20,
                alignItems: 'center',
              }}
            >
              <Input
                type="checkbox"
                checked={internalFractionalAllowed}
                onChange={(e) => setInternalFractionalAllowed(e.target.checked)}
              />
              {' '}
              Internal Fractional Allowed
            </Label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => warningWithConfirmAndCancelMessage(updateTickerStatusMutation, {
              ticker: ticker.symbol.S,
              availableForTrade: internalAvailableForTrade,
              sellOnly: internalSellOnly,
              fractionalAllowed: internalFractionalAllowed,
            })}
          >
            Save Changes
          </Button>
          {' '}
          <Button color="secondary" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default UpdateTickerModal;
