/* eslint-disable import/no-unresolved */
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from 'Screens/Components/Sidebar/Sidebar';
import routes from 'routes';

export default function ProtectedLayout() {
  return (
    <div className="flex h-screen bg-zinc-800">
      <Sidebar routes={routes} activeColor="blue" />
      <div className="flex-grow">
        <Outlet />
      </div>
    </div>
  );
}
