/* eslint-disable no-console */
import { APIRequestWithAuth } from './config';

/**
 * Requeue an event by its ID and event type
 * @param {string} eventId - ID of the event to requeue
 * @param {string} eventType - Type of the event to requeue
 * @returns {Promise<any>}
 */
export const requeueEvent = async (eventId, eventType) => {
  try {
    // Send POST request to the backend with both eventId and eventType
    const payload = { eventId, eventType };
    const response = await APIRequestWithAuth('POST', '/admin/events/requeue', payload);

    // Check if the response has a status field and if it's not 'success'
    if (!response || response.status !== 'success') {
      throw new Error(`Failed to requeue event. Response: ${JSON.stringify(response)}`);
    }

    // Log the successful requeue operation
    console.log('Requeue Response:', response);

    // Return the requeue details
    return response;
  } catch (error) {
    console.error('Error requeuing event:', error);

    // Return an error response
    return { status: 'error', message: 'Failed to requeue event', details: error.message };
  }
};

/**
 * Fetch all unmatched events
 * @param {string} prefix - Optional prefix to filter unmatched events
 * @returns {Promise<any>}
 */
export const getUnmatchedEvents = async () => {
  try {
    const data = await APIRequestWithAuth('GET', '/admin/events/unmatched');
    console.log('data', data);
    return data || [];
  } catch (error) {
    console.error('Error fetching unmatched events:', error);
    return [];
  }
};

/**
 * Fetch all accounts reconciliation data
 * @returns {Promise<any>}
 */
export const getAllAccountsReconciliation = async () => {
  const data = await APIRequestWithAuth('GET', '/admin/reconciliation/accounts');
  if (data) {
    return data;
  }
  return null;
};

/**
 * Fetch SOD data
 * @returns {Promise<any>}
 */
export const getApexSOD = async () => {
  const data = await APIRequestWithAuth('GET', '/admin/reconciliation/sod');
  if (data) {
    return data;
  }
  return null;
};

/**
 * Trigger manual reconciliation
 * @param {string} eventType - Type of the event to reconcile
 * @returns {Promise<any>}
 */
export const manualReconciliation = async (eventType) => {
  const payload = { eventType };
  const data = await APIRequestWithAuth('POST', '/admin/reconcile/manual', payload);
  if (data) {
    return data;
  }
  return null;
};

/**
 * Fetch reconciliation logs
 * @returns {Promise<any>}
 */
export const getReconciliationLogs = async () => {
  const data = await APIRequestWithAuth('GET', '/admin/logs/reconciliation');
  if (data) {
    return data;
  }
  return null;
};

/**
 * Fetch reconciliation statistics
 * @returns {Promise<any>}
 */
export const getReconciliationStats = async () => {
  const data = await APIRequestWithAuth('GET', '/admin/stats/reconciliation');
  if (data) {
    return data;
  }
  return null;
};

/**
 * Retry a failed reconciliation event
 * @param {string} eventId - ID of the failed event to retry
 * @returns {Promise<any>}
 */
export const retryFailedEvent = async (eventId) => {
  const payload = { eventId };
  const data = await APIRequestWithAuth('POST', '/admin/reconcile/retry', payload);
  if (data) {
    return data;
  }
  return null;
};

/**
 * Fetch event details by event ID
 * @param {string} eventId - ID of the event
 * @returns {Promise<any>}
 */
export const getEventDetails = async (eventId) => {
  const data = await APIRequestWithAuth('GET', `/admin/events/${eventId}`);
  if (data) {
    return data;
  }
  return null;
};

/**
 * Fetch event types
 * @returns {Promise<any>}
 */
export const getEventTypes = async () => {
  const data = await APIRequestWithAuth('GET', '/admin/event-types');
  if (data) {
    return data;
  }
  return null;
};
