/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Container, Row, Col, Card, CardBody, CardTitle, Spinner, Alert,
} from 'reactstrap';
import ReactTable from 'Screens/Components/ReactTable/ReactTable';

export default function ProposalDetailsTable({ proposalQuery }) {
  return (
    <Container fluid className="mt-4">
      <div style={{ marginLeft: '18%', marginRight: '5%' }}>
        <Row className="justify-content-center">
          <Col xs={12} md={12}>
            {proposalQuery.isLoading && (
            <Spinner color="primary" />
            )}
            {proposalQuery.isError && (
            <Alert color="danger">Error fetching data</Alert>
            )}
            {proposalQuery.isSuccess && proposalQuery.data && (
            <>
              <Card className="mb-4">
                <CardBody>
                  <CardTitle tag="h2">Proposal</CardTitle>
                  <ReactTable
                    isSearchActive
                    tableRoute="groups"
                    data={[proposalQuery.data]}
                    columns={[
                      { Header: 'Proposal ID', accessor: 'id' },
                      { Header: 'End Date', accessor: 'end_date' },
                      { Header: 'Message', accessor: 'message' },
                      { Header: 'Status', accessor: 'status' },
                      { Header: 'Investing Group ID', accessor: 'investing_group_id' },
                      { Header: 'Created At', accessor: 'createdAt' },
                      { Header: 'Updated At', accessor: 'updatedAt' },
                    ]}
                  />
                </CardBody>
              </Card>
              <Card className="mb-4">
                <CardBody>
                  <CardTitle tag="h2">Proposal Votes</CardTitle>
                  <ReactTable
                    isSearchActive
                    tableRoute="proposalVotes"
                    data={proposalQuery.data.ProposalVotes}
                    columns={[
                      { Header: 'Vote ID', accessor: 'id' },
                      { Header: 'Proposal ID', accessor: 'proposal_id' },
                      { Header: 'User ID', accessor: 'user_id' },
                      { Header: 'Vote', accessor: 'vote' },
                      { Header: 'Created At', accessor: 'createdAt' },
                      { Header: 'Updated At', accessor: 'updatedAt' },
                    ]}
                  />
                </CardBody>
              </Card>
              <Card className="mb-4">
                <CardBody>
                  <CardTitle tag="h2">Users</CardTitle>
                  <ReactTable
                    isSearchActive
                    tableRoute="users"
                    data={proposalQuery.data.Users}
                    columns={[
                      { Header: 'User ID', accessor: 'id' },
                      { Header: 'First Name', accessor: 'first_name' },
                      { Header: 'Last Name', accessor: 'last_name' },
                      { Header: 'Email', accessor: 'email' },
                      { Header: 'Username', accessor: 'user_name' },
                      { Header: 'PDT Protection', accessor: 'pdt_protection', Cell: ({ value }) => (value ? 'Yes' : 'No') },
                      { Header: 'Checklist Complete', accessor: 'checklist_complete', Cell: ({ value }) => (value ? 'Yes' : 'No') },
                      { Header: 'Created At', accessor: 'createdAt' },
                      { Header: 'Updated At', accessor: 'updatedAt' },
                    ]}
                  />
                </CardBody>
              </Card>
              <Card className="mb-4">
                <CardBody>
                  <CardTitle tag="h2">Positions</CardTitle>
                  <ReactTable
                    isSearchActive
                    tableRoute="positions"
                    data={proposalQuery.data.Positions}
                    columns={[
                      { Header: 'Position ID', accessor: 'id' },
                      { Header: 'Cash Contribution', accessor: 'cash_contribution' },
                      { Header: 'External Order ID', accessor: 'external_order_id' },
                      { Header: 'Proposal ID', accessor: 'proposal_id' },
                      { Header: 'User ID', accessor: 'user_id' },
                      { Header: 'Created At', accessor: 'createdAt' },
                      { Header: 'Updated At', accessor: 'updatedAt' },
                    ]}
                  />
                </CardBody>
              </Card>
            </>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
}
