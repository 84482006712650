/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';
import { Nav, Collapse, Button } from 'reactstrap';
import { useAuthenticator } from '@aws-amplify/ui-react';
import '../../../assets/css/hedgeStyle.css';
import AuthenticationService from 'Services/Auth.service';
import { useDispatch } from 'react-redux';

let ps;

function Sidebar({ routes, activeColor, logo, closeSidebar }) {
  const [state, setState] = useState({});
  const sidebarRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    setState(getCollapseStates(routes));
  }, [routes]);

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return () => {
      if (ps) ps.destroy();
    };
  }, []);

  const getCollapseStates = (routes) => {
    const initialState = {};
    routes.forEach((prop) => {
      if (prop.collapse) {
        initialState[prop.state] = getCollapseInitialState(prop.views);
        Object.assign(initialState, getCollapseStates(prop.views));
      }
    });
    return initialState;
  };

  const getCollapseInitialState = (routes) => {
    return routes.some(
      (route) =>
        (route.collapse && getCollapseInitialState(route.views)) ||
        window.location.href.indexOf(route.path) !== -1
    );
  };

  const activeRoute = (routeName) => (location.pathname === routeName ? 'active' : '');

  const handleLogout = async () => {
    await AuthenticationService.handleSignOut(dispatch);
  };

  // Move the createLinks function here to avoid "Cannot access 'createLinks' before initialization"
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) return null;

      if (prop.collapse) {
        return (
          <li className={getCollapseInitialState(prop.views) ? 'active' : ''} key={key}>
            <a
              href="#collapse"
              aria-expanded={state[prop.state]}
              aria-controls={`collapse-${key}`}
              onClick={(e) => {
                e.preventDefault();
                setState((prevState) => ({
                  ...prevState,
                  [prop.state]: !prevState[prop.state],
                }));
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                  <b className="caret" />
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">{prop.name}</span>
                  <b className="caret" />
                </>
              )}
            </a>
            <Collapse isOpen={state[prop.state]} id={`collapse-${key}`}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }

      return (
        <li className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} onClick={closeSidebar}>
            {prop.icon ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };

  const memoizedLinks = useMemo(() => createLinks(routes), [routes, state]);

  const logoSection = logo ? (
    <div className="logo">
      {logo.outterLink ? (
        <>
          <a href={logo.outterLink} className="simple-text logo-mini" target="_blank" rel="noreferrer">
            <div className="logo-img">
              <img src={logo.imgSrc} alt="Logo" />
            </div>
          </a>
          <a href={logo.outterLink} className="simple-text logo-normal" target="_blank" rel="noreferrer">
            {logo.text}
          </a>
        </>
      ) : (
        <>
          <NavLink to={logo.innerLink} className="simple-text logo-mini" onClick={closeSidebar}>
            <div className="logo-img">
              <img src={logo.imgSrc} alt="Logo" />
            </div>
          </NavLink>
          <NavLink to={logo.innerLink} className="simple-text logo-normal" onClick={closeSidebar}>
            {logo.text}
          </NavLink>
        </>
      )}
    </div>
  ) : null;

  return (
    <div className="sidebar" data={activeColor}>
      <div className="sidebar-wrapper" ref={sidebarRef}>
        {logoSection}
        <div className="user-info">
          <i className="tim-icons icon-single-02" />
          <p>{user ? user.signInDetails.loginId : ''}</p>
        </div>
        <Nav>{memoizedLinks}</Nav>
        <div className="logout-button">
          <Button color="danger" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
