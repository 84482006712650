/* eslint-disable import/no-unresolved */
/* eslint-disable */

import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Button,
  Col,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Popover,
  PopoverHeader,
  PopoverBody,
  Progress,
  UncontrolledCollapse,
  UncontrolledTooltip,
  Badge,
  Spinner,
  Input, // Added Input for event type dropdown
} from 'reactstrap';
import { useQuery } from 'react-query';
import { getUnmatchedEvents, requeueEvent } from '../../Services/ReconciliationServices';
import axios from 'axios';
import moment from 'moment';
import ReactTable from '../Components/ReactTable/ReactTable';
import Sidebar from '../Components/Sidebar/Sidebar';
import AdminNavbar from '../Components/Navbars/AdminNavbar'; // Import AdminNavbar
import routes from 'routes.js';

// Import Creative Tim Components
import SweetAlert from 'react-bootstrap-sweetalert';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

// Import CSS styles
import '../../assets/css/hedgeStyle.css';
import './components/TableComponent.css';
import '../../assets/css/colors.css'; // Added the color palette

function UnmatchedEvents() {
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState([]);
  const [helpModal, setHelpModal] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false); // State for popover
  const [eventDetailsModal, setEventDetailsModal] = useState(false);
  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
  const [additionalDetails, setAdditionalDetails] = useState(null); // New state for additional details
  const [isFetchingDetails, setIsFetchingDetails] = useState(false); // Loading state for fetching details
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState([]);
  const [pauseRequeue, setPauseRequeue] = useState(false);
  const [sidebarOpened, setSidebarOpened] = useState(true); // Track sidebar open/close state
  const [alert, setAlert] = useState(null); // SweetAlert state
  
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [dateRange, setDateRange] = useState('30'); // Default to 30 days
  const [selectedEventType, setSelectedEventType] = useState('all'); // Default to 'all' event types
  
  // Function to hide SweetAlert
  const hideAlert = () => {
    setAlert(null);
  };
  
  // Function to toggle sidebar state
  const handleMiniClick = () => {
    setSidebarOpened(!sidebarOpened);
  };
  
  // Function to get the current active route name for Navbar brandText
  const getActiveRoute = (routes) => {
    const activeRoute = routes.find((route) => route.path === window.location.pathname);
    return activeRoute ? activeRoute.name : 'Unmatched Events';
  };
  
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
  };
  
  // Function to fetch order details
  const getOrderDetails = async (tradeDate, externalId) => {
    try {
      const response = await axios.get(`/admin/orders/${tradeDate}/${externalId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching order details:', error);
      throw error;
    }
  };
  
  // Function to get badge color based on status
  const getStatusColor = (status) => {
    if (!status) {
      return 'secondary'; // Default color if status is undefined or null
    }
  
    switch (status.toLowerCase()) {
      case 'queued':
      case 'success':
      case 'completed':
        return 'success';
      case 'pending':
        return 'warning';
      case 'failed':
      case 'error':
        return 'danger';
      case 'unknown':
        return 'dark';
      default:
        return 'secondary';
    }
  };  
  
  // Function to process events for the chart based on selected event type and date range
  const getEventsPerDay = (events, range, eventType) => {
    const today = moment().startOf('day');
    const pastDates = [];
    
    for (let i = range - 1; i >= 0; i--) {
      pastDates.push(today.clone().subtract(i, 'days'));
    }
    
    const eventsPerDay = pastDates.map((date) => ({
      date: date.format('YYYY-MM-DD'),
      count: 0,
    }));
    
    events
      .filter((event) => eventType === 'all' || event.eventType === eventType) // Filter by event type
      .forEach((event) => {
        const eventDate = moment(event.s3LastModified).format('YYYY-MM-DD');
        const day = eventsPerDay.find((d) => d.date === eventDate);
        if (day) {
          day.count += 1;
        }
      });
    
    return eventsPerDay;
  };
  
  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 15,
          color: 'var(--primary-color)',
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          color: 'var(--primary-color)',
        },
        grid: {
          color: 'var(--border-color)',
        },
      },
    },
  };
  
  const getChartData = () => {
    const labels = chartData.map((d) => moment(d.date).format('MMM DD'));
    const dataValues = chartData.map((d) => d.count);
    
    return {
      labels,
      datasets: [
        {
          label: 'Total Events',
          data: dataValues,
          borderColor: 'var(--primary-color)',
          backgroundColor: 'rgba(25, 228, 33, 0.4)',
          fill: true,
          tension: 0.4,
        },
      ],
    };
  };
  
  // Fetch unmatched events with react-query, dependent on dateRange and eventType
  const getUnmatchedEventsQuery = useQuery(['getUnmatchedEvents', dateRange, selectedEventType], () => getUnmatchedEvents(), {
    onSuccess: (data) => {
      if (data && data.body && Array.isArray(data.body)) {
        // Filter data based on selected date range
        const filteredData = data.body.filter(event => {
          const eventDate = moment(event.s3LastModified);
          const startDate = moment().startOf('day').subtract(parseInt(dateRange) - 1, 'days');
          return eventDate.isSameOrAfter(startDate);
        });

        setData(
          filteredData.map((event, key) => ({
            id: key,
            eventId: event.eventId,
            eventType: event.eventType,
            status: (
              <Badge color={getStatusColor(event.status)}>
                {event.status}
              </Badge>
            ),
            eventDate: moment(event.eventDate).format('MM/DD/YYYY HH:mm:ss A'),
            s3Path: event.s3Path,
            s3LastModified: moment(event.s3LastModified).format('MM/DD/YYYY HH:mm:ss A'),
            side: event.side || 'N/A',
            symbol: event.symbol || 'N/A',
            shareQuantity: event.shareQuantity || 'N/A',
            avgPrice:
              event.avgPrice !== undefined && !isNaN(event.avgPrice)
                ? event.avgPrice.toFixed(2)
                : 'N/A',
            notional:
              event.notional !== undefined && !isNaN(event.notional)
                ? event.notional.toFixed(2)
                : 'N/A',
            actions: (
              <div className="actions-right">
                <Button
                  color="info"
                  size="sm"
                  className={`btn-icon ${loadingEvents.includes(event.eventId) ? 'loading' : ''}`}
                  onClick={() => handleRequeue(event.eventId, event.eventType)}
                  disabled={loadingEvents.includes(event.eventId)}
                >
                  {loadingEvents.includes(event.eventId) ? (
                    <i className="fa fa-redo fa-spin" title="Requeueing..." />
                  ) : (
                    <i className="fa fa-redo" title="Requeue Event" />
                  )}
                </Button>{' '}
                <Button
                  color="success"
                  size="sm"
                  className="btn-icon"
                  onClick={() => handleViewEventDetails(event)}
                >
                  <i className="fa fa-eye" title="View Event" />
                </Button>{' '}
                <Button color="danger" size="sm" className="btn-icon">
                  <i className="fa fa-times" title="Delete Event" />
                </Button>
              </div>
            ),
          }))
        );
        
        const eventsPerDay = getEventsPerDay(filteredData, parseInt(dateRange), selectedEventType);
        setChartData(eventsPerDay);
      } else {
        setAlert(
          <SweetAlert
            danger
            title="Error"
            onConfirm={hideAlert}
          >
            Failed to load unmatched events. Please try again later.
          </SweetAlert>
        );
      }
    },
    onError: (error) => {
      setAlert(
        <SweetAlert
          danger
          title="Error"
          onConfirm={hideAlert}
        >
          {`Error fetching unmatched events: ${error.message}`}
        </SweetAlert>
      );
    },
  });
  
  const handleRequeue = async (eventId, eventType) => {
    try {
      setLoadingEvents((prevLoadingEvents) => [...prevLoadingEvents, eventId]);
      setAlert(
        <SweetAlert
          info
          title="Info"
          onConfirm={hideAlert}
        >
          {`Requeue request for event ${eventId} has started`}
        </SweetAlert>
      );
      
      await requeueEvent(eventId, eventType);
      
      // Update the event status to "QUEUED"
      setData((prevData) => {
        return prevData.map((event) => {
          if (event.eventId === eventId) {
            return {
              ...event,
              status: (
                <Badge color="success">
                  QUEUED
                </Badge>
              ),
            };
          }
          return event;
        });
      });
      
      setAlert(
        <SweetAlert
          success
          title="Success"
          onConfirm={hideAlert}
        >
          {`Event ${eventId} requeued successfully!`}
        </SweetAlert>
      );
    } catch (error) {
      // Handle any errors that occur during the requeue process
      setAlert(
        <SweetAlert
          danger
          title="Error"
          onConfirm={hideAlert}
        >
          {`Failed to requeue event ${eventId}: ${error.message}`}
        </SweetAlert>
      );
      setErrors((prevErrors) => [...prevErrors, { eventId, error: error.message }]);
      setPauseRequeue(true);
    } finally {
      // Ensure the loading state is updated, whether the try block succeeded or failed
      setLoadingEvents((prevLoadingEvents) => prevLoadingEvents.filter((id) => id !== eventId));
    }
  };
  
  const handleRequeueSelected = async () => {
    setProgress(0);
    const totalEvents = selectedEvents.length;
    const newErrors = [];
    
    for (let i = 0; i < totalEvents; i++) {
      if (pauseRequeue) break;
      
      const eventId = selectedEvents[i];
      const eventType = data.find((event) => event.eventId === eventId)?.eventType;
      
      try {
        await handleRequeue(eventId, eventType);
        setProgress(((i + 1) / totalEvents) * 100);
      } catch (error) {
        newErrors.push({ eventId, error: error.message });
        setPauseRequeue(true);
        break;
      }
    }
    
    setErrors(newErrors);
    
    if (newErrors.length > 0) {
      setAlert(
        <SweetAlert
          danger
          title="Error"
          onConfirm={hideAlert}
        >
          {`Some events failed to requeue. Failed events: ${newErrors.map((e) => e.eventId).join(', ')}`}
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          success
          title="Success"
          onConfirm={hideAlert}
        >
          All selected events requeued successfully.
        </SweetAlert>
      );
    }
  };
  
  const continueRequeue = () => {
    setPauseRequeue(false);
    handleRequeueSelected();
  };
  
  const handleViewEventDetails = (event) => {
    setSelectedEventDetails(event);
    setEventDetailsModal(true);
  };
  
  const fetchAdditionalDetails = async () => {
    if (isFetchingDetails || additionalDetails) return; // Prevent duplicate fetches
    setIsFetchingDetails(true);
    
    try {
      const { eventId, eventType, eventDate } = selectedEventDetails;
      
      if (eventType === 'orders') {
        const tradeDate = moment(eventDate, 'MM/DD/YYYY HH:mm:ss A').format('YYYY-MM-DD');
        const orderDetails = await getOrderDetails(tradeDate, eventId);
        setAdditionalDetails(orderDetails);
      } else {
        // Fetch additional details for other event types if needed
        setAdditionalDetails(null);
      }
    } catch (error) {
      console.error('Error fetching additional details:', error);
      setAlert(
        <SweetAlert
          warning
          title="Warning"
          onConfirm={hideAlert}
        >
          Failed to fetch additional details.
        </SweetAlert>
      );
    } finally {
      setIsFetchingDetails(false);
    }
  };
  
  const closeEventDetailsModal = () => {
    setSelectedEventDetails(null);
    setAdditionalDetails(null);
    setEventDetailsModal(false);
    setIsFetchingDetails(false);
  };
  
  const handleSelect = (eventId) => {
    setSelectedEvents((prevSelected) => {
      if (prevSelected.includes(eventId)) {
        return prevSelected.filter((id) => id !== eventId);
      }
      return [...prevSelected, eventId];
    });
  };
  
  const toggleHelpModal = () => setHelpModal(!helpModal);
  
  const handleRefresh = () => {
    getUnmatchedEventsQuery.refetch();
  };
  
  return (
    <div className="flex h-screen" style={{ backgroundColor: 'var(--background-color)' }}>
      <AdminNavbar
        handleMiniClick={handleMiniClick}
        brandText={getActiveRoute(routes)}
        sidebarOpened={sidebarOpened}
        toggleSidebar={toggleSidebar}
      />
      <Sidebar
        routes={routes}
        logo={{
          innerLink: '/admin/dashboard',
          imgSrc: require('../../assets/img/hedge-logo-gradient.png'),
          text: 'Hedge Admin Dashboard',
        }}
        activeColor="green"
        isOpen={sidebarOpened} // Ensure the sidebar receives the state for open/close
      />
      <div className="flex-grow-1">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center unmatched-events-title">Unmatched Events</h2>
        </Col>
        <div style={{ marginLeft: '18%', marginRight: '5%' }}>
        
        {/* Event Type Selector */}
        <Row className="mb-3">
          <Col md={3}>
            <Input
              type="select"
              value={selectedEventType}
              onChange={(e) => setSelectedEventType(e.target.value)}
            >
              <option value="all">All Events</option>
              <option value="orders">Orders</option>
              <option value="transfers">Transfers</option>
              {/* Add other event types here */}
            </Input>
          </Col>
        </Row>
        
        {/* Date Range Selection */}
        <Row className="mb-3">
          <Col md={3}>
            <Button
              color={dateRange === '7' ? 'primary' : 'secondary'}
              onClick={() => setDateRange('7')}
              className="me-2"
            >
              1 Week
            </Button>
            <Button
              color={dateRange === '30' ? 'primary' : 'secondary'}
              onClick={() => setDateRange('30')}
              className="me-2"
            >
              30 Days
            </Button>
            <Button
              color={dateRange === '90' ? 'primary' : 'secondary'}
              onClick={() => setDateRange('90')}
            >
              90 Days
            </Button>
          </Col>
        </Row>
        
        <Row className="mt-5">
          <Col xs={12} md={12}>
            {chartData.length > 0 && (
              <Card className="mb-4">
                <CardBody style={{ height: '350px' }}>
                  <h4 className="chart-title">
                    Unmatched Events Over the Last {dateRange === '7' ? '1 Week' : dateRange === '30' ? '30 Days' : '90 Days'}
                  </h4>
                  <div style={{ height: '100%' }}>
                    <Line data={getChartData()} options={chartOptions} />
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
          <Col xs={12} md={12}>
            <Card className="table-container">
              <CardHeader>
                <Button onClick={handleRefresh} style={{ borderColor: 'var(--primary-color)' }} size="sm" className="btn-icon">
                  <i className="tim-icons icon-refresh-01" />
                </Button>{' '}
                <Button color="info" onClick={toggleHelpModal} size="sm" className="btn-icon">
                  <i className="fa fa-question-circle" title="Help" />
                </Button>
              </CardHeader>
              <CardBody>
                {alert}
                
                {progress > 0 && (
                  <div style={{ marginBottom: '10px' }}>
                    <span>Processing: {progress.toFixed(0)}%</span>
                    <Progress value={progress} className="mb-3" />
                  </div>
                )}
                
                {data && data.length > 0 ? (
                  <ReactTable
                    tableRoute="unmatchedEvents"
                    data={data.map((event, index) => ({
                      ...event,
                      selection: (
                        <input
                          type="checkbox"
                          onChange={() => handleSelect(event.eventId)}
                          checked={selectedEvents.includes(event.eventId)}
                        />
                      ),
                    }))}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: 'Select',
                        accessor: 'selection',
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'Event ID',
                        accessor: 'eventId',
                      },
                      {
                        Header: 'Event Type',
                        accessor: 'eventType',
                      },
                      {
                        Header: 'Status',
                        accessor: 'status',
                      },
                      {
                        Header: 'Event Date',
                        accessor: 'eventDate',
                      },
                      {
                        Header: 'S3 Path',
                        accessor: 's3Path',
                      },
                      {
                        Header: 'S3 Last Modified',
                        accessor: 's3LastModified',
                      },
                      {
                        Header: 'Actions',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                ) : (
                  <p style={{ color: 'var(--primary-color)' }}>Loading...</p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        
        {selectedEvents.length > 0 && (
          <Button
            color="success"
            className="fixed-bottom-right"
            onClick={handleRequeueSelected}
          >
            Requeue {selectedEvents.length} Event(s)
          </Button>
        )}
        </div>
      </div>
      
      {/* Modal for Event Details */}
      {selectedEventDetails && (
        <Modal isOpen={eventDetailsModal} toggle={closeEventDetailsModal} className="event-details-modal">
          <ModalHeader toggle={closeEventDetailsModal}>
            <h2>Event Details - {selectedEventDetails.eventId || selectedEventDetails.externalId}</h2>
            <button type="button" className="btn-close" aria-label="Close" onClick={closeEventDetailsModal}></button>
          </ModalHeader>
          <ModalBody>
            <p><strong>Event Type:</strong> {selectedEventDetails.eventType}</p>
            <p><strong>Event Date:</strong> {selectedEventDetails.eventDate}</p>
            <p>
              <strong>Status:</strong>{' '}
              <Badge color={getStatusColor(selectedEventDetails.status)}>
                {selectedEventDetails.status}
              </Badge>
            </p>
            <p><strong>Side:</strong> {selectedEventDetails.side}</p>
            <p><strong>Symbol:</strong> {selectedEventDetails.symbol}</p>
            <p><strong>Share Quantity:</strong> {selectedEventDetails.shareQuantity}</p>
            <p><strong>Avg Price:</strong> {selectedEventDetails.avgPrice}</p>
            <p><strong>Notional:</strong> {selectedEventDetails.notional}</p>
            <p><strong>S3 Path:</strong> {selectedEventDetails.s3Path}</p>
            <p><strong>S3 Last Modified:</strong> {selectedEventDetails.s3LastModified}</p>
            
            <Button color="link" href="#viewAllDetails" id="viewAllDetailsToggler" onClick={fetchAdditionalDetails}>
              {selectedEventDetails.externalId ? 'View All Order Details' : 'View All Event Details'}
            </Button>
            
            <UncontrolledCollapse toggler="#viewAllDetailsToggler">
              <Card>
                <CardBody>
                  {isFetchingDetails ? (
                    <div style={{ textAlign: 'center' }}>
                      <Spinner color="primary" />
                      <p>Loading additional details...</p>
                    </div>
                  ) : additionalDetails ? (
                    <pre
                      style={{ backgroundColor: '#f8f9fa', padding: '15px', borderRadius: '5px' }}
                    >
                      {JSON.stringify(additionalDetails, null, 2)}
                    </pre>
                  ) : (
                    <pre
                      style={{ backgroundColor: '#f8f9fa', padding: '15px', borderRadius: '5px' }}
                    >
                      {JSON.stringify(selectedEventDetails, null, 2)}
                    </pre>
                  )}
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={closeEventDetailsModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
      
      {/* Help Modal */}
      <Modal isOpen={helpModal} toggle={toggleHelpModal} className="help-modal">
        <ModalHeader toggle={toggleHelpModal}>
          <h2>Help Information</h2>
          <button type="button" className="btn-close" aria-label="Close" onClick={toggleHelpModal}></button>
        </ModalHeader>
        <ModalBody>
          <h4>How to Use the Unmatched Events Interface</h4>
          <h5>This interface allows you to view, select, and manage unmatched events in the system.</h5>
          <ul>
            <li>
              <strong>Requeue Events:</strong>
              Click the requeue icon
              <Button id="PopoverRequeue" onClick={() => setPopoverOpen(!popoverOpen)} size="sm" color="secondary" className="btn-sm">
                ?
              </Button>
              <Popover placement="right" isOpen={popoverOpen} target="PopoverRequeue" toggle={() => setPopoverOpen(!popoverOpen)}>
                <PopoverHeader>Requeue Events</PopoverHeader>
                <PopoverBody>Reprocess the event by clicking the requeue button.</PopoverBody>
              </Popover>
              next to an event or select multiple events and click the "Requeue" button to reprocess them.
            </li>
            <li><strong>View Event Details:</strong> Click the eye icon to view detailed information about an event.</li>
            <li><strong>Delete Events:</strong> Click the delete icon (X) to remove an event from the system.</li>
            <li>
              <strong>Select Multiple Events:</strong>
              Use the checkboxes to select multiple events and take action on them together.{' '}
              <a href="/#" id="selectInfo">More Info</a>
              <UncontrolledTooltip placement="top" target="selectInfo">
                You can select multiple events by clicking the checkboxes on the left of each event.
              </UncontrolledTooltip>
            </li>
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleHelpModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      
      {pauseRequeue && (
        <Modal isOpen={pauseRequeue}>
          <ModalHeader>Error Occurred</ModalHeader>
          <ModalBody>
            <p>
              An error occurred during the requeue process. Would you like to continue with the
              remaining events?
            </p>
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{`Event ID: ${error.eventId}, Error: ${error.error}`}</li>
              ))}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={continueRequeue}>
              Continue
            </Button>
            <Button color="danger" onClick={() => setPauseRequeue(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}

export default UnmatchedEvents;
