/* eslint-disable react/prop-types */
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import routes from 'routes.js';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  CardTitle,
} from 'reactstrap';
import { useQuery } from 'react-query';
// eslint-disable-next-line import/no-unresolved
import { getTransferHistoryById, cancelAchDeposit, cancelAchWithdrawal } from 'Services/AccountServices';
import moment from 'moment';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import SimpleTable from '../Components/SortingTable/SortingTable';
import Sidebar from '../Components/Sidebar/Sidebar';

function CancelTransfer({ transferData }) {
  const nonCancellableStates = [
    'Completed',
    'Returned',
    'RejectedValidationFailed',
    'RejectedUnapproved',
    'RejectedFundsNotPosted',
    'RejectedNotSentToBank',
    'Canceled',
  ];
  const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);
  const [showFailAlert, setShowFailAlert] = React.useState(false);
  const [memo, setMemo] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const handleMemoChange = (event) => {
    setMemo(event.target.value);
  };

  // make sure that none of the objects in transferData array have a state in unCancellableStates
  const isCancellable = transferData.every((data) => !nonCancellableStates.some((state) => data.state === state));
  const isDeposit = transferData[0].type === 'ACH_DEPOSIT';

  const handleCancelTransfer = async () => {
    setIsLoading(true);
    let cancelResponse = null;
    if (isDeposit) {
      const body = {
        depositId: transferData[0].transferId,
        reason: memo,
      };
      cancelResponse = await cancelAchDeposit(body);
    } else {
      const body = {
        withdrawalId: transferData[0].transferId,
        reason: memo,
      };
      cancelResponse = await cancelAchWithdrawal(body);
    }

    if (cancelResponse) {
      setIsLoading(false);
      setShowSuccessAlert(true);
    } else {
      setIsLoading(false);
      setShowFailAlert(true);
    }
  };

  if (isCancellable && isDeposit) {
    return (

      <div>
        <ReactBSAlert
          show={showSuccessAlert}
          title="Cancel ACH Deposit Succesful"
          onConfirm={() => setShowSuccessAlert(false)}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
        />

        <ReactBSAlert
          show={showFailAlert}
          title="Cancel ACH Deposit Failed"
          onConfirm={() => setShowFailAlert(false)}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
        >
          Please Contact Support
        </ReactBSAlert>
        <div>
          <div style={{ marginBottom: '1%' }}>
            <label style={{ marginRight: '1%', fontSize: 30, marginTop: '1%' }}>
              To cancel this ACH Deposit, please add a comment and click the button below to submit your request. Please note, this action is irreversible.
            </label>
          </div>
          <div>
            <input type="text" value={memo} onChange={handleMemoChange} size="100" />
          </div>
          <div>

            <button style={{ marginTop: '1%' }} type="button" disabled={!memo || isLoading} onClick={handleCancelTransfer}>{!isLoading ? 'Cancel ACH Deposit' : 'Cancelling...'}</button>

          </div>
        </div>
      </div>

    );
  }
  if (isCancellable && !isDeposit) {
    return (

      <div>
        <ReactBSAlert
          show={showSuccessAlert}
          title="Cancel ACH Withdrawal Succesful"
          onConfirm={() => setShowSuccessAlert(false)}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
        />

        <ReactBSAlert
          show={showFailAlert}
          title="Cancel ACH Withdrawal Failed"
          onConfirm={() => setShowFailAlert(false)}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
        >
          Please Contact Support
        </ReactBSAlert>
        <div>
          <div style={{ marginBottom: '1%' }}>
            <label style={{ marginRight: '1%', fontSize: 30, marginTop: '1%' }}>
              To cancel this ACH Withdrawal, please add a comment and click the button below to submit your request. Please note, this action is irreversible.
            </label>
          </div>
          <div>
            <input type="text" value={memo} onChange={handleMemoChange} size="100" />
          </div>
          <div>

            <button style={{ marginTop: '1%' }} type="button" disabled={!memo || isLoading} onClick={handleCancelTransfer}>{!isLoading ? 'Cancel ACH Withdrawal' : 'Cancelling...'}</button>

          </div>
        </div>
      </div>

    );
  }
}

export default function TransferDetails() {
  const { externalTransferId } = useParams();
  const [transfersByAccountTable, setTransfersByAccountTable] = React.useState(null);
  const [transferData, setTransferData] = React.useState(null);

  // eslint-disable-next-line no-unused-vars
  const getAllTransfersQuery = useQuery('getTransfersById', () => getTransferHistoryById(externalTransferId), {
    onSuccess: (data) => {
      setTransferData(data);
      const transfersTables = data.map((reason) => ([
        { label: 'Amount', value: reason.amount },
        { label: 'Account', value: reason.account },
        { label: 'Status', value: reason.state },
        { label: 'Reason', value: reason.reason },
        { label: 'Transfer ID', value: reason.transferId },
        { label: 'External Transfer ID', value: reason.externalTransferId },
        { label: 'Type', value: reason.type },
        { label: 'UUID', value: reason.id },
        { label: 'Date Submitted', value: moment(reason.timestamp).format('MM/DD/YYYY HH:mm:ss A') },
      ]));
      setTransfersByAccountTable(transfersTables);
    },
  });

  return (
    <div className="flex h-screen bg-zinc-800">
      <Sidebar
        routes={routes}
        activeColor="blue"
      />
      <div className="flex justify-center items-start mt-10">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">Transfer Details</h2>
        </Col>
        <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
          <div>
            <Row className="mt-5">
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Transfer History for
                      {externalTransferId}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {transfersByAccountTable && transfersByAccountTable.length > 0 ? (
                      <SimpleTable
                        thead={[{ text: 'Amount' }, { text: 'Account' }, { text: 'Status' }, { text: 'Reason' }, { text: 'Transfer ID' }, { text: 'External Transfer ID' }, { text: 'Type' }, { text: 'UUID' }, { text: 'Date Submitted' }]}
                        tbody={transfersByAccountTable.map((rows) => ({
                          data: rows.map((row) => ({ text: row.value })),
                        }))}
                      />

                    ) : (
                      <text> loading... </text>
                    )}

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <div>
            {transfersByAccountTable && transfersByAccountTable.length > 0 && (
            <CancelTransfer transferData={transferData} />

            )}
          </div>
        </div>
      </div>
    </div>
  );
}
