/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-unresolved
import Sidebar from 'Screens/Components/Sidebar/Sidebar';
import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
// eslint-disable-next-line import/no-unresolved
import routes from 'routes.js';

import { useQuery } from 'react-query';
import {
  getWhitelist, searchWhitelist,
  refreshDiffs,
} from 'Services/WhitelistService';
import { useDispatch } from 'react-redux';
import { addStockToEdits } from 'store/Reducers/whitelist';
import { Button } from 'reactstrap';
import Pagination from './components/Pagination';
import SearchBar from './components/SearchBar';
import ApexDiffTable from './components/ApexDiffTable';
import PolygonDiffTable from './components/PolygonDiffTable';
import CombinedDiffTable from './components/CombinedDiffTable';
import ChangesTable from './components/ChangesTable';

export default function Whitelist() {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [chartData, setChartData] = useState([]);
  const [searching, setSearching] = useState(false);
  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    { field: 'symbol', filter: true },
    { field: 'securityName', filter: true },
    { field: 'active', editable: true },
    { field: 'type', editable: true },
    { field: 'locale', editable: true },
    { field: 'currency_name', editable: true },
    { field: 'cik', editable: true },
    { field: 'composite_figi', editable: true },
    { field: 'share_class_figi', editable: true },
    { field: 'market', editable: true },
    { field: 'currency_symbol', editable: true },
    { field: 'primary_exchange', editable: true },
    { field: 'base_currency_symbol', editable: true },
    { field: 'base_currency_name', editable: true },
    { field: 'delisted_utc', editable: true },
    { field: 'sic_code', editable: true },
  ]);
  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));

  const whitelistQuery = useQuery(['whitelist', page], () => getWhitelist(page), {
    onSuccess: (data) => {
      if (!searching) setChartData(data);
    },
  });

  const pageClicked = (page) => {
    setPage(page);
  };

  const onSearch = async (searchText) => {
    const res = await searchWhitelist(searchText);
    setChartData(res);
  };

  const onCellValueChanged = useCallback(
    (event) => {
    // Retrieve the field that was edited and its new value
      const { colDef, newValue, data } = event;
      const updatedField = colDef.field;

      // Create a new updated data object with the edited field updated
      const updatedData = { ...data };
      updatedData[updatedField] = newValue;

      // Dispatch the action with the updated data
      dispatch(addStockToEdits(updatedData));
    },
    [dispatch],
  );

  const handleRefresh = async () => {
    // refreshDiffs();
    await refreshDiffs();
  };

  const handleWhitelistRefresh = async () => {
    await whitelistQuery.refetch();
  };

  return (
    <div className="d-flex flex-row h-screen bg-zinc-800 w-screen">
      <div
        className="w-screen bg-zinc-800"
        style={{
          width: '100%',
          marginLeft: '18%',
          marginRight: '5%',
          marginTop: '5%',
          overflowX: 'auto',
        }}
      >
        <Button onClick={handleRefresh} style={{ marginBottom: '5%' }} color="success" size="lg">Handle Backend Diff Refresh</Button>
        <div style={{ marginBottom: '20%' }}>
          <div className="bg-zinc-800" style={{ width: '100%', height: 600 }}>
            <ChangesTable />
          </div>
        </div>
        <div style={{ marginBottom: '20%' }}>
          <div className="bg-zinc-800" style={{ width: 500, height: 1500 }}>
            <ApexDiffTable />
          </div>
        </div>
        <div style={{ marginBottom: '20%' }}>
          <div className="bg-zinc-800" style={{ width: '100%', height: 1500 }}>
            <PolygonDiffTable />
          </div>
        </div>
        <div style={{ marginBottom: '5%' }}>
          <div className="bg-zinc-800" style={{ width: '100%', height: 1500 }}>
            <CombinedDiffTable />
          </div>
        </div>
        <div style={{ marginTop: '20%' }}>
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center',
          }}
          >
            <h1>Hedge Whitelist</h1>
            <Button onClick={handleWhitelistRefresh} style={{ marginBottom: '2%', marginLeft: '3%' }} size="md">Refresh</Button>
          </div>
          <SearchBar onSearch={onSearch} setSearching={setSearching} whitelistQuery={whitelistQuery} setChartData={setChartData} />
          <div className="bg-zinc-800" style={{ width: '100%', height: 1500 }}>
            <AgGridReact
              className="ag-theme-alpine"
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows
              rowSelection="multiple"
              onCellValueChanged={onCellValueChanged}
              rowData={chartData}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Center horizontally
              alignItems: 'center', // Center vertically
              marginTop: 20,
            }}
          >
            <Pagination totalPages={90} currentPage={page} onPageChange={pageClicked} />
          </div>
        </div>
      </div>
    </div>
  );
}
